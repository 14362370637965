@import "../globals.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


.navBar {
    font-family: 'Montserrat';
    font-style: normal;
    min-height: 130px;
    background: $blue;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
        height: 64px;
        width: 285px;
    }

    .navList {
        display: flex;
        font-family: 'Montserrat';
        font-style: normal;
        margin-right: 20px;

        .navItem {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            list-style-type: none;
            margin: 0px 10px;
            height: 24px;
            top: 46px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 24px;
            cursor: pointer;
            color: #FFFFFF;
        }

        button {
            font-family: inherit;
        }
    }

    .authBtnContainer {
        font-family: 'Montserrat';
        font-style: normal;
        margin-right: 0;
        display: flex;

        button {
            font-family: inherit;
        }
    }

}

@media (min-width:2560px) {

    .logo {
        height: 67px * 1.2 !important;
        width: 300px * 1.2 !important;
    }

    .navList {

        .navItem {
            font-size: 16px * 1.3 !important;
        }

        .authBtnContainer {
            font-family: 'Montserrat';
            font-style: normal;
        }

    }
}

@media (min-width:3840px) {

    .logo {
        height: 67px * 1.5 !important;
        width: 300px * 1.5 !important;
    }

    .navList {

        .navItem {
            font-size: 16px * 1.5 !important;
        }

    }
}

.dropbtn {
    list-style-type: none;
    margin: 0px 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    cursor: pointer;
    color: #FFFFFF;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContent {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdownContent a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdownContent a:hover {
    background-color: #f1f1f1
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdownContent {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    color: $gold;
}