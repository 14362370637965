@import "../globals.scss";

.Container {
    background: $blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    height: 89vh;
    
    h1 {
        width: 80%;
        font-size: 27px;
        margin: 20px auto;
    }

    section {
        text-align: center;
        height: 65vh;
        padding: 10px;
        width: 80%;
        margin: 10px auto;
        border: 1px solid $gold;
        border-radius: 20px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 5px;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
            background: #00308F;
            border-radius: 20px;
            height: 90%;
            margin-top: 15px;
            margin-bottom: 15px;
        }

        &::-webkit-scrollbar-thumb {
            background: #F1F1F1;
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: #F1F1F1;
        }


        .header {
            display: flex;
            justify-content: center;
            align-items: center;

            h1 {
                color: $gold;
                text-align: left;
                margin: 0 15px;
            }

            select {
                color: white;
                background-color: $blue;
                border: 1px solid $gold;
                font-size: 16px;
                font-weight: bold;
                border-radius: 10px;
                width: max-content;
            }
        }

        table {
            width: 100%;
            margin: 20px 0px;
            border: none;
            border-collapse: collapse;

            thead {
                font-size: 20px;
                font-weight: 600;
                border-bottom: 2px solid white;
            }

            tr {
                border-bottom: 1px solid white;

                td {
                    padding: 0.5rem 0;
                    text-transform: capitalize;
                }

                td:first-child {
                    width: 30%;
                    border-left: none;
                }

                td:last-child {
                    border-right: none;
                }

            }
        }

        .loaderSection {
            width: 100%;
            text-align: center;
            margin: 45px 0;
        }

    }

    .LodingContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.dropBtn {
    color: white;
    background-color: #091A49;
    border: 1px solid #E8CB69;
    font-size: 16px;
    font-weight: bold;
    border-radius: 10px;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
    padding: 5px 20px;
    min-height: 47px;
    width: 350px;
}
