@import "../globals.scss";

.container {
  padding: 50px;
  display: flex;
  flex-direction: column;
  background: $blue;
  justify-content: flex-start;
  gap: 20px;
  height: 89.2vh;
  overflow-y: hidden;
  position: relative;

  .backdrop {
    position: fixed;
    left: -7.29%;
    right: 55.7%;
    top: 40.65%;
    bottom: -27.96%;

    img {
      background: transparent;
      opacity: 0.1;
    }
  }
}