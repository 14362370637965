@import "../globals.scss";

.introjs-tooltiptext{
    font-size: 21px;
    font-weight: 600;
    padding: 7px 10px !important;
    color: $gold;
}

.introjs-button{
    background: $gold !important;
    color: white !important;
    border: 1px solid $gold;
    font-weight: 500 !important;
    outline: 0px !important;
    text-shadow: $blue 4px 3px 8px 4px, rgb(33 33 33 / 50%) 0px 0px 0px 5000px!important;
}
.myTooltipClass{
    background: $blue !important;
    color: $gold !important;
}

.introjs-skipbutton{
    color: $white !important;
    height: 30px !important;
}

// .introjs-helperLayer .highlightClass{
//     background: $blue !important;
//     color: $gold !important;
// }
// .myHighlightClass{
//     background: $gold !important;
//     color: $gold !important;
// }
.introjs-bullets{
    display: none !important;
}