@import "../globals.scss";
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    height: 82vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $blue;
    background: url('../assets/NewPhotos/mainBg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include phone {
        background: $blue;
    }

    .bg-image {
        @include phone {
            background: url('../assets/NewPhotos/mainBg.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-position-x: -20.5rem;
        }
    }

    .TextDiv {
        width: 50%;

        @media screen and (max-width: 768px) {
            width: 100%;
            text-align: center;
            margin: 0px;
            padding: 5px;
        }

        h1 {
            margin-top: 40px;
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 600;
            font-size: 54px;
            line-height: 69px;
            text-transform: capitalize;
            color: #FFFFFF;


            @include scr-2k {
                margin-top: 100px;
                margin-left: 80px;
                font-weight: 700;
                // font-size: 70px;
                line-height: 80px;
                text-align: left;

            }

            @media screen and (max-width: 768px) {
                font-size: 25px;
                line-height: 32px;
            }
        }

        h4 {
            // padding-left: 70px;
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            margin-top: 15px;
            font-size: 25px;
            line-height: 40px;
            text-align: left;
            color: #FFFFFF;

            @include scr-2k {
                // margin-left: 20px;
                font-weight: 600;
                font-size: 30px;
                line-height: 40px;
                // padding: 10px;
            }

            @include phone {
                font-size: 16px;
                line-height: 28px;
                text-align: center;
            }
        }
    }


    img {
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        @include scr-2k {
            width: 100vw;
            height: 100vh;

        }
    }
}


.container_two {
    display: flex;
    font-family: 'Rubik';

    @media screen and (max-width: 768px) {
        padding-top: 6rem !important;
    }

    img {
        width: 100vw;

        @media screen and (max-width: 768px) {
            width: 100vw;
            margin: 0px;
        }

        @include scr-2k {
            // width: calc(100% - 1100px);
        }
    }

    h2 {
        position: relative;
        margin-left: 50px;
        font-family: 'Rubik';
        font-weight: 700;
        text-align: right;
        line-height: 55px;
        color: #091A49;
        padding: 100px 0px !important;

        @include scr-2k {
            top: calc(100vh - 900px);
            font-weight: 700;
        }

        @include phone {
            // width: 100vw;
            line-height: 26px;
            margin: 0px auto !important;
            padding: 25px !important;
            font-weight: bold;
            font-size: 20px;
            line-height: 28px;
        }

    }

    h2 h4 {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        text-align: right;
        color: #091A49;

        @include scr-2k {
            // font-size: 40px;
            // line-height: 60px;
        }

        @include phone {
            // width: 100vw;
            font-size: 16px;
            text-align: right;
            line-height: 22px;
            width: 80%;
            margin-left: auto;
        }

        b {
            font-weight: bold;
        }
    }


}

.container_three img {
    height: 97vh;
    width: 100vw;

    @media screen and (max-width: 768px) {
        height: 100%;
        width: 100vw;
        object-fit: contain;
    }
}

.container_four {
    display: flex;

    @media screen and (max-width: 768px) {
        padding-top: 0px !important;
        margin-top: 20px !important;
    }

    img {

        @include scr-2k {
            width: calc(100% - 40%);
        }

        // @include phone{
        //     margin-top: 0px;
        // }
    }

    h2 {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        color: #091A49;
        // margin: 35px 0px;
        margin-top: 5rem;

        @include scr-2k {}

        @include phone {
            text-align: left;
            font-weight: bolder;
            // font-weight: 25px;
            font-size: 20px;
        }

        h4 {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            text-transform: none;
            color: #091A49;
            line-height: 37px !important;
            margin: 20px 0px;

            @include scr-2k {
                // font-size: 40px;
                // line-height: 50px;
            }

            @include phone {
                font-size: 16px;
                text-align: left;
                line-height: 23px;
            }

            b {
                font-weight: bold;
            }

        }
    }



}

.container_five {
    background-color: $blue;
    display: flex;
    justify-content: space-between;
    height: 90vh;

    @include phone {
        flex-direction: row-reverse;
        height: 87vh;
    }

    .ImgDiv {
        width: 58%;
        height: 100%;
        background: url('../assets/FinalLandingPage/five_one.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        // img {
        //     object-fit: ;
        //     width: 100%;
        //     height: 100%;

        //     @include scr-2k {
        //         padding-top: 180px;
        //         height: 100vh;
        //         width: calc(100% - 35%);
        //     }

        //     @include phone {
        //         margin-top: 0px;
        //         width: 100%;
        //     }
        // }
        @include phone {
            height: 40vh;
            width: 100%;
        }
    }

    h2 {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        color: #FFFFFF;
        margin: auto 25px;

        @include scr-2k {
            padding-top: 250px;
        }


        @include phone {
            padding: 40px 10px !important;
            width: 100%;
            margin: 0px !important;
            font-size: 20px;
            font-weight: bold;
        }
    }

    h4 {
        margin-left: 5px;
        font-weight: 200;
        align-self: end;
        color: #FFFFFF;

        @include scr-2k {
            font-size: 35px;
            line-height: 45px;
        }

        @include phone {
            font-size: 16px;
            line-height: 24px;
            // line-height: 22px;
            padding-left: 25px;
            padding-right: 25px;
            // text-align: right;
            // padding: 10px;
        }
    }
}

.container_six {
    display: flex;
    height: 90vh !important;

    @include phone {
        height: 95vh !important;
    }

    h2 {
        font-family: 'Rubik';
        font-style: normal;
        line-height: 50px;
        font-weight: 700;
        padding: 45px 0px !important;
        color: $blue;
        // margin: 10px 0px;
        margin-top: 25px;
        margin-bottom: 25px;

        @include scr-2k {
            font-weight: 800;
        }

        @include phone {
            margin-top: 28px;
            margin-bottom: 0px;
            margin-left: 28px;
            text-align: left;
            font-size: 20px;
            line-height: 28px;
            font-weight: bolder;
            // padding-left: 30px;
        }

        @include sm-laptop {
            font-size: 32px;
            line-height: 40px;
            padding: 15px 0px !important;
        }

        h4 {
            font-weight: 400;
            font-size: 23px;
            line-height: 32px;
            color: $blue;

            @include scr-2k {
                font-weight: 400;
            }

            @include phone {
                font-size: 16px;
                line-height: 24px;
                text-align: left;
            }

            @include sm-laptop {
                font-size: 22px;
            }

            b {
                font-weight: bold;
            }
        }
    }

    .ImgDiv {
        width: 58%;
        height: 100%;
        background: url('../assets/FinalLandingPage/six_one.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @include phone {
            height: 34vh;
            width: 100%;
            // margin-bottom: 20px;
        }
    }
}

.container_seven {
    display: flex;
    background: $NewGold;
    // justify-content: flex-start;

    @include phone {
        height: 78vh !important;
    }

    h2 {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        color: $blue;
        padding: 150px 40px !important;

        @include scr-2k {
            font-weight: 700;
        }

        @include phone {
            text-align: right;
            font-size: 20px;
            line-height: 25px;
            width: 100%;
            padding: 25px 20px !important;
        }

        h4 {
            font-weight: 400;
            color: $blue;
            line-height: 40px;

            @include scr-2k {
                font-weight: 350;
            }

            @include phone {
                width: 70%;
                margin-left: auto !important;
                text-align: right;
                font-size: 16px;
                line-height: 24px;
                // margin: 0px auto !important;
            }

            b {
                font-weight: bold;
            }
        }
    }

    .ImgDiv {
        width: 62%;
        height: 100%;
        background: url('../assets/FinalLandingPage/seven_one.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @include phone {
            height: 40vh;
            width: 100%;
        }
    }

}

.container_eight {
    background: url('../assets/FinalLandingPage/eight_one.png') $blue;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    h2 {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        color: $blue;
    }
}

.container_nine {
    background: url('../assets/FinalLandingPage/partners.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;

    @media screen and (max-width: 1279px) {
        background: url('../assets/FinalLandingPage/MobilePartners.png') #01194A;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
    }

    @media screen and (max-width: 768px) {
        height: 82vh !important;
    }

    h2 {
        text-align: center;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        font-size: 46px;
        position: absolute;
        top: 10%;
        left: 42%;
        color: #FFFFFF;
        text-transform: uppercase;

        @include scr-2k {
            margin-top: 90px;
            font-weight: 800;
            font-size: 60px;
            line-height: 40px;

        }

        @media screen and (max-width: 1279px) {
            display: none;
        }


        @media screen and (max-width: 768px) {
            display: none;
        }
    }

}

.container_ten {
    display: flex;
    background-color: #FFFFFF;

    @include scr-2k {
        width: calc(100% - 40%);
    }

    @include phone {
        justify-content: center;
        align-items: center;
    }

    h2 {
        position: relative;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 800;
        color: #091A49;

        @include phone {
            font-size: 20px;
            text-align: left;
            font-weight: bolder;
            padding: 10px;
        }

        h4 {
            @include phone {
                margin-top: 13px;
                font-size: 16px;
                line-height: 22px;
                text-align: left;
            }

            @include sm-laptop {
                font-size: 19px !important;
            }
        }
    }

    .text_two {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-feature-settings: 'case'on;
        color: #091A49;

        @include scr-2k {
            // top: 160px;
            // left: 120px;

            // font-weight: 400;
            // font-size: 35px;
            // line-height: 40px;

        }

    }
}

.container_eleven {
    background: $NewGold;
    color: $blue;
    display: flex;
    position: relative;

    @include phone {
        flex-direction: row-reverse;
    }

    img {

        // height: 100vh;
        @include scr-2k {
            height: 100vh;
            width: 50%;
        }

        @include phone {
            width: 200px;
            height: 200px;
        }
    }

    h1 {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        padding-top: 50px;
        margin-top: 50px;

        @include phone {
            text-align: right;
            font-size: 20px;
            margin-top: -76px;
            font-weight: bolder;
            padding: 25px 15px;
        }

        h4 {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 26px;
            line-height: 36px;
            margin-top: 30px;

            @include scr-2k {
                font-weight: 400;
            }

            @include phone {
                margin-top: 35px;
                text-align: right;
                font-size: 16px;
                line-height: 22px;
                padding: 0px !important;
            }
        }

        @include scr-2k {
            font-size: 60px;
        }
    }
}

.container_twelve {
    position: relative;
    width: 100vw;
    background-color: $blue;

    .faq_screen h2 {
        position: relative;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        text-align: center;
        justify-content: center;
        color: #FFFFFF;

        @include scr-2k {
            font-weight: 700;
        }
    }

    .questions {
        position: relative;
        cursor: pointer;
    }

    .que h3 {
        position: relative;
        cursor: pointer;
        color: #FFFFFF;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;

        @include scr-2k {
            font-weight: 400;
        }
    }

    .que {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-radius: 60px;
        border: 2px solid #DBB42C;

        @media screen and (max-width: 768px) {
            margin-top: 0.7rem;
            margin-bottom: 0.7rem;
        }
    }

    .open {
        border-radius: 30px
    }

    .que h2 img {
        width: 30px;
        height: 30px;
    }

    .que h4 {
        color: #FFFFFF;
        padding: 20px;
        justify-content: flex-start;

        @include scr-2k {
            font-size: 35px;
        }
    }

    .que p.active {
        padding: 20px;
    }

}

.faq_screen {
    width: 100vw;
    margin: auto;
}

.container_thirteen {
    background: #DBB42C;
    width: 100vw;

    h2 {
        padding-top: 30px;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        text-align: center;
        color: $blue;

        @media screen and (max-width: 768px) {
            font-size: 25px;
            // padding: 0px;
        }
    }

    form {
        padding-top: 50px;
        width: 80%;
        margin: 0 auto;

        .input-row {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: 0 0 20px;

            @media screen and (max-width: 768px) {
                flex-direction: column;
                justify-content: flex-start;
            }

            label {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 30px;
                line-height: 44px;
                width: 25%;
                color: $blue;

                @media screen and (max-width: 768px) {
                    // height: 50vh !important;
                    line-height: 23px !important;
                    font-size: 20px !important;
                    width: 100%;
                    margin-bottom: 10px;
                }
            }

            input {
                display: flex;
                padding: 5px 15px 5px;
                width: 75%;
                background: #FFE382;
                opacity: 0.7;
                border: 3px solid #AE8E1C;
                border-radius: 20px;
                font-family: 'Rubik';
                font-style: normal;
                font-size: 34px;
                color: #091A49;

                @include phone {
                    width: 100%;
                    font-size: 28px;
                }
            }

            textarea {
                display: flex;
                padding: 5px 15px 5px;
                width: 75%;
                background: #FFE382;
                opacity: 0.7;
                border: 3px solid #AE8E1C;
                border-radius: 20px;
                font-family: 'Rubik';
                font-style: normal;
                font-size: 34px;
                color: #091A49;

                @include phone {
                    width: 100%;
                    font-size: 28px;
                }
            }

        }

        .input-row-referrer {
            margin: 0 0 25px;

            label {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 30px;
                line-height: 44px;
                width: 15%;
            }

            input {
                display: flex;
                padding: 5px 15px 5px;
                width: 100%;
                background: #FFE382;
                opacity: 0.7;
                border: 3px solid #AE8E1C;
                border-radius: 20px;
                font-family: 'Rubik';
                font-style: normal;
                font-size: 37px;
                color: #091A49;
            }
        }

        .formErrorText {
            color: red;
            font-size: 21px;
        }

        .submit_class_div {
            width: 100%;
            padding: 0 0 35px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
        }

        .submit_class {
            align-self: center;
            padding: 20px;
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 24px;
            text-align: center;
            color: #091A49;
            height: 70px;
            width: 150px;
            background: #FFE382;
            opacity: 0.7;
            border: 3px solid #AB8A1A;
            border-radius: 40px;

            @media screen and (max-width: 768px) {
                // height: 50vh !important;
                height: 40px;
                width: 120px;
                font-size: 18px;
                padding: 0px;
            }
        }

    }
}

.content1 {
    background: #FFE382;
    opacity: 0.7;
    border: 3px solid #AE8E1C;
    border-radius: 20px;
}

.content {
    display: inline-block;
    margin-top: 10px;
    margin-right: 20px;
    align-items: right;
    width: calc(100vw - 30vw);
    height: 70px;
    background: #FFE382;
    opacity: 0.7;
    border: 3px solid #AE8E1C;
    border-radius: 20px;
}

.container_fourteen {
    background-color: #091A49;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    width: 100vw;
    color: white;

    img {
        margin-left: 20px;
    }

    a {
        color: #FFFFFF;
        font-size: 20px;
        padding-right: 40px;

        @include scr-2k {
            font-size: 30px;
            padding-right: 150px;
        }
    }

    @include scr-2k {

        display: flex;
        align-items: center;
        justify-content: space-between;

    }
}