@import "../globals.scss";

.Container {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: auto;

    h2 {
        @include Fonts(28px, 50px, 600);
        color: $blue;
    }

    .division {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;

        .innerdiv {
            display: flex;
            flex-direction: row;
            gap: 8px;
            margin: 10px 0px;

            h3 {
                @include Fonts(18px, 18px, 600);
                color: $blue;
                margin-top: 5px;
            }

            img {
                width: 30px;
                height: 30px;
            }
        }
    }

    .Payinfo {
        margin: 15px 0px;
        overflow-y: scroll;
        height: 320px;

        h4 {
            color: $blue;
            @include Fonts(18px, 20px, 600);
            margin: 5px 0px;
        }

        table {
            width: 100%;
            margin: 10px 0px;

            tr {
                margin: 40px 0px;
                // padding: 10px;
                height: 33px;

                .values {
                    @include Fonts(15px, 30px, 600);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    grid-gap: 12px;
                    line-height: 27px;
                    margin: auto 10px;
                }
            }
        }
    }

    .checkboxSection {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        grid-gap: 10px;

        input {
            width: 25px;
            height: 25px;
        }

    }

    .btndiv {
        text-align: center;
        margin: 10px 0px;

        button {
            width: 245px;
            height: 55px;
            @include Btn;

            &:hover {
                color: $blue;
                background: #D5B54A;
            }
        }
    }
}

.copyBtn {
    cursor: pointer;
}