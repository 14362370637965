@import "../globals.scss";

.container {
    border: 1px solid $gold;
    border-radius: 10px;
    height: 65vh;
    overflow-y: auto;

    .header {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        height: 8vh;

        .permission {
            display: flex;
            align-items: flex-start;
            grid-gap: 15px;


            select {
                background: transparent;
                color: white;
                border: 1px solid $gold;
                border-radius: 10px;
                width: max-content;
                height: 50px;
                @include Fonts(16px, 20px, bold);
                padding: 5px;

                @include scr-2k {
                    font-size: 22px;
                    width: 275px;
                    height: 60px;
                    padding: 0 15px;
                }
                
                @include scr-4k {
                    font-size: 35px;
                    width: 375px;
                    height: 80px;
                    padding: 0 20px;
                }

                option {
                    background: white;
                    color: $blue;
                    padding: 5px;
                }

            }
            
            .dropdownBtn {
                background: transparent;
                color: white;
                border: 1px solid $gold;
                border-radius: 10px;
                width: 455px;
                height: 50px;
                @include Fonts(16px, 20px, bold);
                padding: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                @include scr-2k {
                    font-size: 22px;
                    width: 900px;
                    height: 60px;
                    padding: 0 15px;
                }
                
                @include scr-4k {
                    font-size: 35px;
                    width: 1455px;
                    height: 80px;
                    padding: 0 20px;
                }

                img {
                    width: 15px;
                    height: 15px;
                }

                
            }

            .permissionDropdown {
                max-height: 30vh;
                overflow-y: auto;
            }

            button {
                width: 200px;
                height: 50px;
                margin: 3px 0;
                @include Btn;
            }
        }


        img {
            width: 30px;
            height: 30px;

            @include scr-2k {
                width: 35px;
                height: 35px;
            }
            
            @include scr-4k {
                width: 55px;
            height: 55px;
            }
        }
    }

    .permission_table {
        margin: 15px 0px;
        overflow-y: auto;
        height: 49.5vh;

        table {
            width: 100%;
            thead {
                margin: 10px 0px;
                
                tr {
                    border-bottom: 2px solid $white;
                    height: 50px;

                    th {
                        color: $white;
                        @include Fonts(20px, 20px, bold);
                        border-bottom: 2px soldi $white;

                        @include scr-2k {
                            font-size: 25px !important;
                            line-height: 25px;
                            padding: 25px 20px;
                          }
                          
                          @include scr-4k {
                            font-size: 40px !important;
                            line-height: 32px;
                            padding: 35px 20px;
                          }
                    }
                }
            }

            tbody {

                tr {
                    text-align: center;
                    height: 50px;

                    td {
                        color: white;
                        @include Fonts(15px, 35px, 500);
                        text-transform: capitalize;
                        border-top: 1px solid $gold;

                        .actionContainer {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding-top: 0.5rem;
                            div {
                                display: flex;
                                margin-right: 1rem;
                                :nth-child(1) {
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    @include scr-2k {
                        font-size: 20px !important;
                        line-height: 25px;
                        padding: 20px 5px;
                      }
                      
                      @include scr-4k {
                        font-size: 33px !important;
                        line-height: 32px;
                        padding: 30px 5px;
                      }

                }
            }
        }
    }
}