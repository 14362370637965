@import '../globals.scss';
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


.main_containter {
    width: 100%;
    font-family: 'Rubik';
    font-style: normal;
    display: flex;
    flex-direction: column;


    .container {
        display: flex;
        flex-direction: column;
        background: url('../assets/UserAgreement.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .inner_div {
            width: 80%;
            margin: auto;

            h1 {
                // margin-left: 80px;
                // // margin-top: 200px;
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 700;
                font-size: 50px;
                line-height: 55px;
                text-align: left;
                color: #DBB42C;

                @include phone {
                    font-size: 28px;
                    // margin-top: 80px;
                    // margin-left: 50px;
                }

                @include scr-2k {
                    margin-top: 400px;
                    margin-left: 80px;
                    font-weight: 700;
                    font-size: 70px;
                    line-height: 80px;
                    text-align: left;
                }

                @include laptop-L {

                    margin-top: 200px;
                    margin-left: 80px;

                    font-weight: 700;
                    font-size: 70px;

                }
            }
        }

        @include phone {
            height: 40vh;
            // margin-top: 70px;
        }
    }

    .container_first {
        // height: 100vh;
        width: 80%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 60px auto;
        gap: 30px;

        .first_para {
            font-weight: 400;
            font-size: 1.5rem;
            line-height: 35px;
            // padding-top: 40px;
            // padding-left: 60px;
            // margin: 70px;
            color: #000000;

            @include scr-2k {
                padding-top: 100px;
                margin: 100px;
                font-weight: 400;
                font-size: 2.8rem;
                line-height: 45px;
            }

            @include phone {
                padding-left: 0px;
                padding-top: 10px;
                font-size: 18px;
                line-height: 28px;
            }
        }

        .second_para {
            // margin: 0 70px 70px 70px;
            font-weight: 400;
            font-size: 1.5rem;
            line-height: 35px;
            // padding-top: 40px;
            // padding-left: 60px;
            color: #000000;

            @include scr-2k {
                padding-top: 100px;
                margin: 100px;
                font-weight: 400;
                font-size: 2.8rem;
                line-height: 45px;
            }

            @include phone {
                padding-left: 0px;
                padding-top: 10px;
                font-size: 18px;
                line-height: 28px;
            }
        }

        img {
            height: 40px;
            width: 100vw;
        }

    }

    .container_second {
        // margin: 0 70px 70px 70px;
        width: 80%;
        margin: 50px auto;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        // padding-top: 70px;
        // padding-left: 60px;

        .ordered_list {

            li {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600px;
                font-size: 1.8rem;
                font-weight: 700;
                padding-bottom: 10px;

                @include phone {
                    font-size: 22px;
                    line-height: 30px;
                    font-weight: 600;
                    // margin-top: 685px;
                    // margin-bottom: 0px;
                }
            }

            // .definations {
            //     @include phone {
            //         margin-top: 685px;
            //     }
            // }

            p {
                // width: 80vw;
                font-family: 'Rubik';
                font-style: normal;
                font-size: 23px;
                line-height: 34px;
                font-weight: 500;
                // padding: 150px;
                color: #000000;

                @include phone {
                    font-size: 18px;
                    // padding: 10px;
                    line-height: 28px;
                }
            }
        }

        @include phone {
            padding: 0px;
        }
    }
}