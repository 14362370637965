@import '../globals.scss';

.container {
  border: 1px solid $gold;
  border-radius: 10px;
  height: 65vh;
  overflow-y: auto;

  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .heading {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: 4vh;
  }
  .subheading {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: 3vh;
  }
}
