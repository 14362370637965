@import "../globals.scss";

.container {
    max-width: 500px;
    margin: auto;


    h2 {
        @include Fonts(32px, 62px, bold);
        color: $blue;
        margin: 10px 0px;
        margin-left: 10px;
    }

    .innerContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;

        .options {
            border: 2.5px solid $brightGold;
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 230px;
            height: 185px;
            border-radius: 20px;
            padding: 15px;
            position: relative;

            img {
                width: 80px;
                height: 80px;
            }
            h4{
                @include Fonts(22px,20px,700);
                color: $blue;
            }
            p{
                @include Fonts(18px,22px,700);
                color: $brightGold;
            }

            span {
                position: absolute;
                bottom: 7px;
                right: 5px;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

.secondary{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    p{
        color: $blue;
        @include Fonts(18px,21px,600);
    }
}