@import "../globals.scss";

@mixin btnstyle {
    background: $gold;
    box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 10px;
    opacity: 0.7;
    color: $blue;
    border: 0px;
    padding: 17px 8px;
    cursor: pointer;

    &:disabled {
        opacity: 0.6;
        color: $blue;
        background: $gray400;
    }

    &:hover {
        background-color: $gold;
        opacity: 1;
    }
}

@mixin OTPbtn{
    font-weight: 600;
    font-size: 18px;
    opacity: 1;
    border: 0px;
    color: $blue;
    background: white;
    padding: 17px 8px;
    cursor: pointer;

    &:disabled{
        color: $gray400;
        cursor: none;
        opacity: 0.8;
    }
}

@mixin Inputs {
    background: $grey500;
    border: 1px solid $grey500;
    width: 564px;
    height: 51px;
    font-weight: 600;
    font-size: 15px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    color: $blue;
    padding: 13px;

    &:disabled {
        opacity: 0.5;
        width: 100%;
        color: $gray;
    }
    &::placeholder{
        opacity: 0.7;
        color: $gray200;
    }

    &:focus-visible {
        outline: white;
    }
}

.Mainconatiner {
    height: 360px;

    h1 {
        font-size: 32px !important;
        display: inline;
        color: $blue !important;
        text-align: start !important;
    }
}

.Subcontainer {
    margin: 10px 0px;
}

.btndiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;

    button {
        background: $blue;
        padding: 15px 26px;
        box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.12);
        border-radius: 40px;
        font-weight: 500;
        font-size: 16px;
        line-height: 10px;
        color: #ffffff;
        border: none;

        &:disabled {
            display: none;
            color: white;
            cursor: none;

        }

        &:hover {
            transition: 0.2s;
            box-shadow: 0 0 0 rgba(0, 0, 0, 0.12);
            opacity: 0.8;
        }
    }
}

.forms {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 0 auto;
    justify-content: center;
    margin: 20px 0px;
    position: relative;

    label {
        color: $gray;
        font-size: 14px;
        margin-bottom: -10px;
    }

    .inputs {
        @include Inputs;
    }

    .timer {
        color: red;
        margin-top: 10px;

    }

    .prime {
        color: #6b6c6f;
        position: absolute;
        margin-top: 10px;
        right: 20px;
    }

    .para {
        color: $gray200;
        font-size: 14px;
        margin: 10px 0px;

        .links {
            color: $blue;
            font-weight: 500;
        }
    }

    .submit {
        @include btnstyle;
        width: 90px;
    }

    .grid {
        display: grid;
        grid-template-columns: 2.3fr 1.2fr 0.5fr;
        grid-template-rows: 1fr;
        justify-content: center;
        align-items: center;
        grid-gap: 15px;
        position: relative;
        margin: 5px 0px ;
        .inputs2 {
            @include Inputs;
            width: 78%
        }

        .verify {
            color: green !important;
            position: absolute;
        }
    }
}

.ResetDiv {
    display: flex;
    justify-content: center;
}

.reset {
    @include OTPbtn;
    margin-left: auto;
}

.reset2 {
    @include btnstyle;
    margin: auto;
    width: 55%;
}

.loading {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 6px;
}

.otphandler {
    width: 60%;
}

.para2 {
    color: $gray200;
    font-size: 14px;
    margin: 10px 0px;

    .links {
        color: $blue;
        font-weight: 500;
    }
}

