@import '../globals.scss';

@mixin Inputs {
  background: $grey500;
  border: 1px solid $grey500;
  width: 564px;
  height: 51px;
  font-weight: 600;
  font-size: 15px;
  // line-height: 21px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  // width: 100%;
  color: $blue;
  padding: 13px;

  @include scr-2k {
    width: 100%;
    height: 75px;
    padding: 20px 10px;
    font-size: 25px;
  }

  @include scr-4k {
    width: 100%;
    height: 100px;
    padding: 20px 10px;
    font-size: 40px;
  }

  &:disabled {
    opacity: 0.5;
    width: 100%;
    color: $gray;
  }
  &::placeholder {
    opacity: 0.7;
    color: $gray200;
  }

  &:focus-visible {
    outline: white;
  }
}

.container {
  border: 1px solid $gold;
  border-radius: 10px;
  height: 65vh;
  overflow-y: auto;

  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: auto;

  h2 {
    text-align: center;
    @include Fonts(25px, 24px, 600);
    margin: 0px;

    @include scr-2k {
      font-size: 32px;
      line-height: 32px;
      margin: 25px 0px;
    }

    @include scr-4k {
      font-size: 55px;
      line-height: 32px;
      margin: 35px 0px;
    }
  }
  .formContainer {
    width: 50%;
  }
  .inputgroup {
    margin: 10px 0px;
    font-size: 18px;
    color: $error;

    @include scr-2k {
      font-size: 25px;
      margin: 35px 0px;
    }

    @include scr-4k {
      font-size: 36px;
      margin: 40px 0px;
    }

    label {
      color: $NewCol;
      font-weight: bold;
      font-size: 14px;
      margin-bottom: -10px;

      @include scr-2k {
        font-size: 32px;
        margin: 20px 0px;
      }

      @include scr-4k {
        font-size: 45px;
        margin: 30px 0px;
      }
    }
    .inputs {
      @include Inputs;
      margin: 10px 0px;
      width: 100%;

      option {
        text-transform: capitalize;
        font-weight: 500;
      }
    }
    .addAdminDropdownBtn {
      @include Inputs;
      margin: 10px 0px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .dropdownList {
      width: inherit !important;
    }

  }
  .btndiv {
    text-align: 'center';
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    button {
      width: 120px;
      height: 45px;
      @include Btn;
    }
  }
}
