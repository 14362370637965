@import "../globals.scss";
@import url('https://fonts.googleapis.com/css2?family=Rubik');
@import url('https://fonts.googleapis.com/css2?family=Montserrat');

main {
    width: 100%;
    padding: 0;
    height: 101vh;
    background-color: #091A49;
    display: grid;
    place-items: center;
    background: radial-gradient(#0000004b,#00000052), url('../assets/FinalLandingPage/one_one.png') no-repeat;
    background-size: cover;
    background-position: center;

    .landing_screen {
        display: grid;
        place-items: center;

        h1 {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 55px;
            text-align: center;
            color: #FFFFFF;
            border-radius: 0%;
        }

        p {
            font-family: 'Rubik';
            font-style: normal;
            color: #D5B54A;
            font-weight: 100;
            line-height: 55px;
            font-size: 50px;
            text-align: center;
        }
    }
}

.main_logo {
    position: relative;
    width: 291px;
    height: 61px;
    left: 192px;
    top: 29px;
}

.login_register {
    position: absolute;
    width: 263px;
    height: 63px;
    left: 1537px;
    top: 27px;
    background: #D5B54A;
    border-radius: 35px;
}

.become_client {
    width: 252px;
    height: 60px;
    box-sizing: border-box;
    text-align: center;
    background-color: #D5B54A;
    border-radius: 30px;
    outline: none;
    border: 0px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 35px;
    text-align: center;
    margin: 45px 10px;
    color: #091A49;
}

.bottom_box {
    position: absolute;
    width: 1332px;
    height: 338.5px;
    left: 303px;
    top: 1058px;
    border: 1px solid #D5B54A;
    border-radius: 10px;
}


.grid-container {
    font-family: 'Montserrat';
    font-style: normal;

    .grid-row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .grid-row_new {
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            span {
                margin: 0px 5px;
            }
        }

        div {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            align-items: center;
            padding: 16px;
            font-size: 1.5rem;
            text-align: center;
            color: #FFFFFF;

            .token-title {
                width: fit-content;
                text-align: center;
            }

            .token-price {
                font-size: 1.2rem;
            }

            .image_size {
                display: flex;
                height: 90px;
                width: 90px;
            }
        }
    }

}

.box {
    position: relative;
    margin: auto;
    width: 78%;
    height: 270px;
    border: 1px solid #D5B54A;
    border-radius: 20px;
    align-items: center;
    top: 90vh;

    .box_p {
        position: absolute;
        margin-left: 25%;
        padding-left: 5px;
        padding-right: 5px;
        top: -18px;
        color: #FFFFFF;
        background-color: #091A49;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
    }

}

.fading_logo {
    position: absolute;
    left: -100px;
    top: 115px;
    height: 85%;
    width: 50%;
    fill: #e9e614;
    opacity: 50%;
}

// Second Screen

.second_screen {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: #FFFFFF;
    border-radius: 10px;
}

.second_screen_text_heading {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 57px;
    text-align: right;
    color: #091A49;
    margin-right: 120px;
    margin-top: 40vh;
}

.second_screen_text {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 32px;
    text-align: right;
    margin-right: 120px;
    color: #091A49;
}