@import "../globals.scss";
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.HeroSection {
    height: 69.2vh;
    background: url('../assets/AllpageImage.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto 0px;
    font-family: 'Rubik';

    @include phone {
        height: 42vh;
        background-position-x: -21rem;
        // margin:20px 0px;
    }

    .textContainer {
        width: 80%;
        margin: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;

        h4 {
            color: white;
            font-size: 20px;
            line-height: 18px;
            margin: 20px 0px;
        }

        h1 {
            color: white;
            font-size: 50px;
            line-height: 44px;
            margin: 20px 0px;
            font-weight: 600;

            @include phone {
                font-size: 28px;
                line-height: 40px;
            }

            span {
                color: $gold;
            }
        }
    }
}

.SpecialDiv {
    border-bottom: 20px solid $gold;
    margin: 35px 0px;
    margin-top: 50px;

    @include phone {
        margin-top: 30px;
    }

    div {
        width: 80%;
        margin: 30px auto;

        p {
            font-size: 22px;
            line-height: 28px;
            margin: 10px 0px;

            @include phone {
                font-size: 18px;
            }
        }

        h4{
            font-size: 26px;
            font-weight: 600;
        }

        ul {
            text-decoration: dashed;
            li {
                font-size: 22px;
                line-height: 28px;
            }
        }
    }
}

.TextSection {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 20px auto;
    font-family: 'Rubik';

    .SmallDiv {
        margin: 20px 0px;

        .borderDiv {
            border: 1px solid $gold;
        }

        h4 {
            font-size: 24px;
            line-height: 18px;
            margin: 21px 0px;
            color: $blue;
            font-weight: 600;

            @include phone {
                font-size: 22px;
                line-height: 27px;
                font-weight: 600;
            }
        }

        p {
            font-size: 22px;
            line-height: 28px;
            margin: 10px 0px;

            @include phone {
                font-size: 18px;
            }

            ul {
                margin: 0px 30px;

                li {
                    margin: 3px 0px;
                    font-size: 22px;
                }
            }
        }

        h5 {
            color: black;
            font-size: 22px;
            margin: 13px 0px;
            line-height: 35px;

            @include phone {
                font-size: 18px;
            }
        }
    }
}