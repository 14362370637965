@import "../globals.scss";

.userDetailsContainer {
    display: grid;
    grid-column: 2fr 2fr;

    .detailsSection {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .detailContainer {
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        width: 50%;

        h6 {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: $brightGold;
        }

        p {
          font-style: normal;
          font-size: 16px;
          line-height: 20px;
          color: #fff;
        }
      }
    }
  }

  .noLogsContainer {
    padding: 10px;
    font-size: 20px;
    color: #ffffff;
  }