@import "../globals.scss";

.Container{
    .heading{
        color: $blue;
        font-size: 30px;
        margin: 15px 0px;
    }
    .Forms{
        display: flex;
        flex-direction: column;
        label{
            color: $blue;
            font-weight: 600;
        }
        .field{
            background: #F1F1F1;
            border: 1px solid #F1F1F1;
            width: 564px;
            height: 51px;
            font-weight: 600;
            font-size: 15px;
            box-shadow: 0px 0px 3px rgb(0 0 0 / 5%);
            border-radius: 10px;
            color: #091A49;
            padding: 13px;
            margin: 10px 0px;
        }
        .dropDownfield{
            background: #F1F1F1;
            border: 1px solid #F1F1F1;
            width: 564px;
            height: 51px;
            font-weight: 600;
            font-size: 15px;
            box-shadow: 0px 0px 3px rgb(0 0 0 / 5%);
            border-radius: 10px;
            color: #091A49;
            padding: 13px;
            margin: 10px 0px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }
    button{
        @include Btn;
        width: 100px;
        height: 40px;
    }
}