@import '../globals.scss';

.currency {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    margin-top: 0.8rem;
    justify-content: space-between;

    .desc1 {
        display: flex;
        flex-direction: row;

        img {
            height: 50px;
        }

        .title {
            display: flex;
            flex-direction: column;
            margin-left: 1rem;
            color: $gray300;
            font-size: 20px;
            font-weight: 500;
            line-height: 22px;

            .symbol {
                display: flex;
                color: $gray300;
                font-weight: 500;
                line-height: 27px;
                font-size: 16px;
                margin-top: -0.2rem;

            }

        }
    }

    .desc2 {
        display: flex;
        flex-direction: row;

    }

    .rate {
        display: flex;
        color: $green;
        font-size: 15px;
        font-weight: 600;

    }

    .price {
        display: flex;
        color: $gray300;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
    }

    .message {
        color: $gray300;
        font-size: 12px;
        line-height: 22px;


    }
    .fees {
        color: rgba(255,255,255,0.8);
        font-size: 12px;
        line-height: 10px;
    }

}