@import "../globals.scss";

.container {
  .tabs {
    display: flex;
    background-color: $brightGold;
    padding: 0 20px;
    border-radius: 10px;

    p {
      padding: 20px;
      cursor: pointer;
      color: white;
      font-weight: 600;

      &.active {
        color: $blue;
        background-color: $white;
      }
    }
  }
}