@import "../globals.scss";

.container {
  display: row;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
  max-width: 700px;
  height: 550px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    display: none;
  }

  h1 {
    margin-bottom: 1rem;
    color: $blue;
  }

  .response {
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
    align-items: flex-start;
    font-size: 13px;
    line-height: 1.3;
    @media screen and (min-width: 3500px) and (min-height: 1900px)  {
      pre {
          margin-bottom: 0rem;
      }
  }

    pre {
      width: 100%;
    }
  }
}
