@import "../globals.scss";

.cookieConsetConatainer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background-color: rgb(0,0,0,.3);

    .cookieConcentBanner {
        width: 100vw;
        padding: 20px 30px; 
        background-color: $blue;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        p {
            color: $white;
        }

        .acceptBtn {
            background-color: rgb(232, 203, 105);
            border: 1px solid transparent;
            color: rgb(9, 26, 73);
            font-weight: 600;
            padding: 10px 20px;
            min-width: 50px;
            font-size: 14px;
            border-radius: 10px;
        }
    }
}