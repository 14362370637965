@import "../globals.scss";

.container {
  background-color: $blue;
  flex: 1;
  position: relative;
  color: white;

  .backdrop {
    position: fixed;
    left: -7.29%;
    right: 55.7%;
    top: 40.65%;
    bottom: -27.96%;
    pointer-events: none;

    img {
      background: transparent;
      opacity: 0.1;
      pointer-events: none;
    }
  }

  .innerContainer {
    width: clamp(500px, 80vw, 1200px);
    margin: 0 auto;
    background: transparent;

    @include scr-2k {
      width: clamp(600px, 80vw, 1600px);
    }

    @include scr-4k {
      width: clamp(650px, 80vw, 1800px);
    }

    .headtag {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .head {
      display: flex;
      align-items: center;
      gap: 70px;

      h1 {
        font-weight: 600;
        font-size: 40px;
        line-height: 85px;
        margin-right: -1.9rem;
      }

      .switch {
        border-radius: 30px;
        position: relative;
        display: flex;
        background-color: $gold;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

        a {
          z-index: 1;

          .option {
            width: 140px;
            padding: 10px 20px;
            cursor: pointer;

            p {
              text-align: center;
              font-weight: 600;
              font-size: 18px;
              line-height: 20px;
              color: #000000;
              transition: 0.2s;
            }

            &.selected {
              p {
                color: white;
              }
            }
          }
        }

        .back {
          border-radius: 30px;
          background-color: #161f48;
          position: absolute;
          width: 140px;
          top: 0;
          left: 0;
          bottom: 0;
          transition: 0.2s;
        }
      }
    }

    .dashboardBalance {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      h3 {
        color: rgba(255, 255, 255, 0.6);
        display: flex;
        flex-direction: column;
        max-width: 400px;

        .coinData {
          display: flex;
        }

        .cryptoCoinData {
          display: flex;
          justify-content: space-between;
          column-gap: 10px;
        }

        .coinTitle {
          margin-right: 0.5rem;
          font-size: 20px !important;
        }

        .coinAmount {
          font-size: 20px !important;
        }
      }
    }

    .backdrop {
      position: absolute;
      top: 0px;
      width: 100%;
    }

    .content {
      display: flex;
      gap: 57px;
      align-items: stretch;
      justify-content: stretch;

      .card {
        flex: 2;
      }
    }
  }
}