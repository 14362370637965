@import "../globals.scss";

/*the container must be positioned relative:*/
.container {
  position: relative;
}

.container select {
  display: none; /*hide original SELECT element:*/
}

.selectSelected {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: space-between !important;
  background: #F1F1F1;
  color:$blue;
  font-weight: 600;
  border: 1px solid #F1F1F1;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
  font-size: 18px;

  @include scr-2k {
    align-items: center;
  }
  
  @include scr-4k {
    align-items: center;
  }

  img {
    display: flex;
    margin-right: 15px;
    height: 25px;
    object-fit: contain;
  }
}

/*style the arrow inside the select element:*/
.selectSelected:after {
  position: absolute;
  content: "";
  top: 21px;
  right: 19px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: $blue transparent transparent transparent;

  @include scr-2k {
    top: 26px;
  }
  
  @include scr-4k {
    top: 26px;
  }
}

/*point the arrow upwards when the select box is open (active):*/
.selectSelected.active:after {
  border-color: transparent transparent $blue transparent;
  top: 12px;

  @include scr-2k {
    top: 17px;
  }
  
  @include scr-4k {
    top: 17px;
  }
}

/*style the items (options), including the selected item:*/
.selectItems div,
.selectSelected {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: space-between;
  padding-right: 10px;
  padding: 10px 20px;
  cursor: pointer;
  user-select: none;
  border-radius: 10px;
  font-size: 18px;
  

  @include scr-2k {
    
    padding: 15px 20px;

  }

  @include scr-4k {

    padding: 15px 20px;

  }
}

.selectItems div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 !important;

    img {
      display: flex;
      margin-right: 10px;
      height: 25px;
      width: 25px;
      object-fit: contain;
    }
}


/*style items (options):*/
.selectItems {
  position: absolute;
  background-color: $blue;
  color: $gold;
  font-weight: 500;
  margin-bottom: 10px;
  top: 100%;
  font-size: medium;
  left: 0;
  right: 0;
  z-index: 999999999999;
  max-height: 60vh;
  width: max-content;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  border: 1px solid $gold;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #00308F;
}

::-webkit-scrollbar-thumb {
  background: #F1F1F1;
}

::-webkit-scrollbar-thumb:hover {
  background: #F1F1F1;
}

.selectItems div:hover,
.same-as-selected {
  background-color: $dropdownHover !important;
  color: $white;
  font-weight: 600;
  border-radius: 0 !important;
}


.itemSelected {
  border-top: 1px solid $gold !important;
  border-bottom: 1px solid $gold !important;
  background-color: $dropdownHover !important;
  color: $white;
}
