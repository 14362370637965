@import "../globals.scss";

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 30px;
  align-items: center;
  height: 75px;
  justify-content: space-between;

  @include scr-2k {
    height: 85px;
    gap: 20px;
  }

  @include scr-4k {
    height: 88px;
    gap: 20px;
  }

  .head {
    display: flex;
    gap: 10px;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      object-fit: contain;


      @include scr-2k {
        width: 60px;
        height: 60px;
      }

      @include scr-4k {
        width: 65px;
        height: 65px;
      }
    }
    .coinPrice{
      font-size: 16px;
      font-weight: 600;
    }
    
    .text {
      min-width: 8rem;
    }

    h2 {
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;

      @include scr-2k {
        font-size: 26px;
        line-height: 30px;
      }

      @include scr-4k {
        font-size: 29px;
        line-height: 35px;
      }
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: white;

      @include scr-2k {
        font-size: 18px;
        line-height: 22px;
      }

      @include scr-4k {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .inner {
      display: flex;
      justify-content: space-between;

      h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        margin-left: 10px;
      }

      h3 {
        font-weight: 600;

        font-size: 18px;
        line-height: 20px;

        &.positive {
          color: #5F9816;
        }

        &.negative {
          color: $error;
        }
      }
    }

    p {
      color: white;
      font-size: 16px;
      line-height: 18px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-end;

    .buybtn {
      width: 103px;
      height: 36px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 23px;
      background-color: $gold;
      font-weight: 600;
      font-weight: 18px;
      line-height: 10px;
      color: $blue;
      border: 1px solid $gold;


      @include scr-2k {
        font-size: 18px;
        line-height: 20px;
        width: 133px;
        height: 40px;
      }

      @include scr-4k {
        font-size: 20px;
        line-height: 22px;
        width: 139px;
        height: 43px;
      }

      &:hover {
        color: $gold;
        border: 1px solid $gold;
        background-color: transparent;
        transition: all 100ms ease-in-out;
      }
    }

    .sellbtn {
      color: $gold;
      border: 1px solid $gold;
      background-color: transparent;
      @extend .buybtn;

      &:hover {
        color: $blue;
        border: 1px solid $gold;
        background-color: $gold;
        transition: all 100ms ease-in-out;
      }
    }
  }
}