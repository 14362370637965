@import "../globals.scss";

@mixin Inputs {
    background: $grey500;
    border: 1px solid $grey500;
    width: 564px;
    height: 51px;
    font-weight: 600;
    font-size: 15px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    color: $blue;
    padding: 13px;

    @include scr-2k {
        width: 100%;
        height: 75px;
        padding: 20px 10px;
        font-size: 25px;
    }
    
    @include scr-4k {
        width: 100%;
        height: 100px;
        padding: 20px 10px;
        font-size: 40px;
    }

    &:disabled {
        opacity: 0.5;
        width: 100%;
        color: $gray;
    }
    &::placeholder{
        opacity: 0.7;
        color: $gray200;
    }

    &:focus-visible {
        outline: white;
    }
}


.Container{
    h2{
        color: $blue;
        @include Fonts(25px,24px,600);
        margin:15px 0px;
        
        @include scr-2k {
            font-size: 32px;
            line-height: 32px;
            margin:25px 0px;
        }
        
        @include scr-4k {
            font-size: 55px;
            line-height: 32px;
            margin:35px 0px;
        }

    }
    .inputgroup{
        margin:10px 0px;
        font-size: 18px;
        color: $error;
        
        @include scr-2k {
            font-size: 25px;
            margin:35px 0px;
        }
        
        @include scr-4k {
            font-size: 36px;
            margin:40px 0px;
        }


        label{
            color: $NewCol;
            font-weight: bold;
            font-size: 14px;
            margin-bottom: -10px;

            @include scr-2k {
                font-size: 32px;
                margin: 20px 0px;
            }
            
            @include scr-4k {
                font-size: 45px;
                margin: 30px 0px;
            }
        }

        .permissionsDropdown {
            height: 150px;
            overflow-y: auto;
        }

        .inputs{
            @include Inputs;
            margin:10px 0px 0px;
            width: 100%;

            option{
                text-transform: capitalize;
                font-weight: 500;
            }
        }

        .menuInputs {
            @include Inputs;
            display: flex;
            justify-content: flex-start;
            margin:10px 0px;
            width: 100%;
            height: auto;

            option{
                text-transform: capitalize;
                font-weight: 500;
            }
        }
        .menuOptions {
            width: 100%;

            span {
                padding: 0 0 0 20px;
            }
        }
    }
    .btndiv{
        text-align:"center";
        display:"flex";
        justify-content:"center";
        align-items:"center";
        button{
            width:120px;
            height:45px;
            @include Btn;
        }
    }
}

.currencyDropBtn {
    background: transparent !important;
    border: none !important;
    border-radius: 10px;
    color: white !important;
    outline: none !important;
    padding: 0 10px;
    font-Size: 12px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $blue;
    min-height: 50px;
}

.permissionsDropdown {
    height: 150px;
    overflow-y: auto;
}