@import "../globals.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  h1{
    font-size: 24px;
    color: $blue;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  
    label {
      color: $blue;
      font-weight: 600;
      font-size: 16px;
  
      @include scr-2k {
        font-size: 20px;
      }
      @include scr-2k {
        font-size: 22px;
      }
  
      &:first-child {
        margin: 0;
      }
    }
  
    input {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 10px;
      background: $grey500;
      border: 1px solid $grey500;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      padding: 5px 20px;
      margin: 3px 0px;
      width: 100% !important;
  
      &::placeholder {
        color: $gray;
      }
  
      &:focus {
        outline: none;
      }
  
      .icon,
      .tool {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        cursor: pointer;
  
        svg,
        img {
          height: 100%;
        }
      }
  
      input {
        padding: 10px 0;
        flex: 1;
      }
  
      min-height: 47px;
    }
  
  
  }
}

.formInner {
  width: 100%;
}