@import "../globals.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  height: 89.2vh;

  .backdrop{
    position: fixed;
    left: -7.29%;
    right: 55.7%;
    top: 40.65%;
    bottom: -27.96%;
  
    img{
      background: transparent;
      opacity: 0.1;
    }
  }

  .sumSubSection {
    z-index: 4;
    overflow-y: auto;

    .levelSelectionSection {
      max-width: 60vw;
      height: 65vh;
      margin: 25px auto 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid $brightGold;
      padding: 3em 0;
      border-radius: 10px;
      

      .selectionTitleText {
        color: white;
        font-size: 25px;
        margin: 0 auto;
      }
      
      .radioBtnsSection {
        margin: 0 auto;
        width: max-content;
        
        input {
          height: 18px;
          width: 18px;
        }

        span {
          color: white;
          font-size: 21px;
        }
      }

      button {
        @include Btn;
        padding: 10px 45px;
      }

    }
  }

  .head {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 400px;
    margin-bottom: 30px;
  }
  .buttonContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    
  }
  .textContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;

    .textSubtitle {
      margin: 20px 0px;
      color: $white;
      font-weight: 600;
      font-size: 1.4rem;
      text-align: center;
      max-width: 80%;
      span{
        color: $gold;
      }
    }
  }
}

.loaderSection  {
  width: 100%;
  height: 89.2vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}