@import '../globals.scss';
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


.main_containter_c {
    font-family: 'Rubik';
    font-style: normal;
    display: flex;
    flex-direction: column;


    .container_c {
        display: flex;
        flex-direction: column;
        background: url('../assets/UserAgreement.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 70vh;

        @include phone {
            height: 40vh;
        }

        .inner_div{
            width: 80%;
            margin: auto;

            h1 {
                // margin-left: 50px;
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 700;
                font-size: 50px;
                line-height: 55px;
                // text-align: left;
                // margin: auto 20;
                color: #DBB42C;

                @include phone {
                    font-size: 30px;
                }

                @include scr-2k {
                    margin-top: 400px;
                    margin-left: 80px;
                    font-weight: 700;
                    font-size: 70px;
                    line-height: 80px;
                    text-align: left;
                }

                @include laptop-L {

                    margin-top: 200px;
                    margin-left: 80px;

                    font-weight: 700;
                    font-size: 70px;

                }
            }
        }
    }

    .container_first_c {
        display: flex;
        flex-direction: column;

        .first_para_c {
            // margin: 70px;
            width: 80%;
            margin: 50px auto;
            font-weight: 400;
            font-size: 22px;
            line-height: 28px;
            color: #000000;

            @include phone {
                font-size: 18px;
                line-height: 25px;
                // margin-bottom: 25px;
            }

            @include scr-2k {
                padding-top: 100px;
                margin: 100px;

                font-weight: 400;
                font-size: 2.8rem;
                line-height: 45px;
            }
        }

        img {
            height: 40px;
        }

    }

    .container_second_c {
        width: 80%;
        margin: 50px auto;

        h1 {
            // margin-left: 70px;
            padding: 0px;
            font-size: 28px;

            @include phone {
                font-size: 22px;
                font-weight: 600;
                // margin-left: 50px;
            }
        }

        p {
            font-family: 'Rubik';
            font-style: normal;

            // margin-left: 70px;
            font-weight: 400;
            font-size: 22px;
            line-height: 28px;

            color: #000000;

            @include phone {
                font-size: 18px;
                line-height: 28px;
                // margin-left: 50px;
            }

        }
    }

}