@import "../globals.scss";

.HeroSection {
    height: 69.2vh;
    background: url('../assets/AllpageImage.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto 0px;
    font-family: 'Rubik';

    @include phone {
        height: 42vh;
        background-position-x: -21rem;
        // margin:20px 0px;
    }

    .textContainer {
        width: 80%;
        margin: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;

        h1 {
            color: white;
            font-size: 50px;
            line-height: 64px;
            margin: 20px 0px;
            font-weight: 700;

            @include phone {
                font-size: 28px;
                line-height: 40px;
            }

            span {
                color: $gold;
            }
        }
    }
}

.SpecialDiv {
    border-bottom: 20px solid $gold;
    margin: 35px 0px;
    margin-top: 50px;

    @include phone {
        margin-top: 30px;
    }

    div {
        width: 80%;
        margin: 30px auto;

        p {
            font-size: 22px;
            line-height: 28px;
            margin: 5px 0px;
            font-family: 'Rubik';

            @include phone {
                font-size: 18px;
            }
        }
    }
}

.Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0px;

    .heading {
        text-align: center;
        font-size: 30px;
        color: $blue;
        text-decoration: underline;
        line-height: 55px;
    }

    .subHeading {
        text-align: center;
        font-size: 17px;
        color: $blue;
    }

    .textDiv {
        width: 80%;
        margin: 22px auto;

        p {
            margin: 18px 0px;
            font-size: 22px;
            line-height: 28px;

            @include phone {
                font-size: 18px;
            }

            b {
                color: black;
            }

            span {
                color: $gold;
            }
        }
    }

    .InnerDiv {
        margin: 10px 0px;

        h4 {
            margin: 10px 0px;
            color: black;
            font-size: 27px;
            font-weight: 700;

            @include phone {
                font-size: 21px;
            }
        }

        ol {
            li {
                margin-left: 20px;
            }
        }

        h5 {
            margin: 20px 0px;
            color: black;
            font-size: 20px;
            font-weight: 600;

            @include phone {
                font-size: 18px;
            }
        }
    }

    .SecondContainer {
        width: 80%;
        margin: 10px auto;

        h4 {
            margin: 24px 0px;
            color: black;
            font-size: 27px;
            line-height: 28px;
            font-weight: 700;
            @include phone {
                font-size: 21px;
            }
        }

        p {
            margin: 22px 0px;
            font-size: 22px;
            line-height: 28px;

            @include phone {
                font-size: 18px;
            }

            b {
                color: black;
            }
        }

        h5 {
            margin: 5px 0px;
            color: black;
            font-size: 20px;
            font-weight: 600;
            @include phone {
                font-size: 18px;
                // line-height: 20px;
            }
        }
    }

    .links {
        width: 80%;
        margin: 20px auto;
        text-align: right;
        color: $blue;
    }
}