@import "../globals.scss";


.container {
  position: relative;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  gap: 20px;
  cursor: pointer;
  border: 1px solid $brightGold;
  border-radius: 10px;

  @include scr-2k {
    padding: 15px 25px;
  }
  
  @include scr-4k {
    padding: 15px 25px;
  }

  h2 {
    font-size: 16px;
    margin: 0 !important;
  }
  .filtericon{
    height: 21px;
    width: 35px;

    @include scr-2k {
      width: 27px;
      height: 41px;
    }
    
    @include scr-4k {
      width: 37px;
      height: 51px;
    }

  }
}

.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  color: $blue;
  margin-top: 5px;
  z-index: 5;
  border-radius: 10px;
  padding: 10px;

  .filter {
    h6 {
      padding: 5px 10px;
      color: $blue !important;
      font-size: 14px !important;
    }
    .option {
      padding: 5px 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}
