@import "../globals.scss";

.title {
  margin-top: 1rem;
  color: white;
  font-weight: 700;
  margin-left: 1rem;
  font-size: 24px;
}
.Mainhead {
  color: white;
  margin: 10px 0px;
  font-size: 24px;
  padding: 5px 10px;
  text-decoration: underline;

  p{
    font-size: 14px;
    text-decoration: none;
  }
}

.container {
  padding: 20px 0;
  margin: 10px 0px;
  height: 42vh;
  overflow-y: auto;
  width: 100%;

  @include tablet {
    height: 35vh;
  }

  .kycContainer {
    display: grid;

    grid-template-columns &:last-child {
      border-bottom: none;

      h3 {
        color: white;
        font-size: 24px;
        margin-left: 1rem;
      }

      .section {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;

        h6 {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
        }

        p {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 20px;
          word-break: break-all;

          &.status {
            font-size: 14px;
            line-height: 20px;
            border-radius: 30px;
            color: #fff;
            padding: 5px 20px;

            &.approved {
              background: $success;
            }

            &.rejected {
              background: $error;
            }
          }
        }

        .button {
          display: flex;
        }
      }
    }

    .btnGrp {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;

      .approve {
        background-color: $success;
        color: white;
        font-size: 16px;
        padding: 20px 40px;
        border: none;
        font-weight: 800;
        border-radius: 10px;
      }

      .reject {
        @extend .approve;
        background-color: $error;
      }
    }
  }


  .userDetailsContainer {
    display: grid;
    grid-column: 2fr 2fr;

    .Mainhead {
      color: white;
      font-size: 24px;
    }

    .detailsSection {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .detailContainer {
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        width: 50%;

        h6 {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: $brightGold;
        }

        p {
          font-style: normal;
          font-size: 16px;
          line-height: 20px;
          color: #fff;

          &.status {
            font-size: 14px;
            line-height: 20px;
            border-radius: 30px;
            padding: 5px 20px;

            &.approved {
              background: $success;
            }

            &.rejected {
              background: $error;
            }
          }
        }

        .button {
          display: flex;
        }
      }
    }

  }
}