@import "../globals.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid $gold;
  border-radius: 20px;
  height: 65vh;
}

.details {
  display: flex;
  align-items: center;
  gap: 20px;

  h1{
    color: $brightGold;
  }
}
