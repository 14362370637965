@import "../globals.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  background: $blue;
  color: $white;
  padding: 20px;
  padding: 30px 0 !important;
  border: 0px !important;
  height: 89vh;
  position: relative;
  box-sizing: border-box;

  @include scr-2k {
    padding: 30px 0px !important;
  }
  @include scr-4k {
    padding: 35px 0px !important;
  }

  .backdrop {
    position: fixed;
    left: -7.29%;
    right: 55.7%;
    top: 40.65%;
    bottom: -27.96%;
    pointer-events: none;
    img {
      background: transparent;
      opacity: 0.1;
      pointer-events: none;
    }
  }

  @include xl-scr {
    gap: 35px;
    padding: 30px;
  }

  .addWalletForm {
    width: 55vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  
    .formElement {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0 20px;
      margin: 0 0 20px 0;
    }

    label {
      // margin: 15px 0 3px 0 ;
      color: white;
      font-weight: 600;
      font-size: 16px;
  
      @include scr-2k {
        font-size: 20px;
      }
      @include scr-2k {
        font-size: 22px;
      }
  
      &:first-child {
        margin: 0;
      }
    }
  
    input {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 10px;
      background: $grey500;
      border: 1px solid $grey500;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      padding: 5px 20px;
      margin: 3px 0px;
      width: 100%;
      color: black;
  
      &::placeholder {
        color: $black
      }
  
      &:focus {
        outline: none;
      }
  
      .icon,
      .tool {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        cursor: pointer;
  
        svg,
        img {
          height: 100%;
        }
      }
  
      input {
        padding: 10px 0;
        flex: 1;
      }
  
      min-height: 47px;
    }
  
  .dropBtn,
  .dropBtn:active {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
    background: #F1F1F1;
    border: 0 !important;
    box-shadow: 0px 0px 3px rgb(0 0 0 / 5%);
    border-radius: 10px;
    padding: 5px 20px;
    margin: 3px 0px;
    width: 100%;
    min-height: 47px;
  }
    
    
  
    .formButtonSection {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0 0;
    }
  }  

  .tableContainer {
    height: 40vh;
    overflow-y: auto;
    table {
      table-layout: auto;
      max-width: 80vw;
      color: $white;

      th {
        color: $gray300;
        align-items: center;
        text-align: center;

      }

      td {
        align-items: center;
      }

      .status {
        &.true {
          color: $success;
        }

        &.false {
          color: $error;
        }
      }
    }
  }
}

.selectItems div {
  background-color: green;
}