@import "../globals.scss";

.container {
  flex: 2.3;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  .title {
    font-weight: 500;
    font-size: 30px;
    color: black;
  }

  .coins {
    .header {
      height: 10%;

      h3 {
        color: white;
        font-weight: 500;
        font-size: 30px;
        line-height: 44px;

        @include scr-2k {
          font-size: 35px;
          line-height: 55px;

        }

        @include scr-4k {
          font-size: 40px;
          line-height: 65px;
        }
      }
    }

    .body {
      height: 58vh;
      overflow-y: scroll;

      @include scr-4k {
        height: 44vh;
      }

      @include scr-2k {
        height: 44vh;
      }

      @include xl-scr {
        height: 50vh;
      }

      &::-webkit-scrollbar {
        display: none;
      }

    }

    .btndiv {
      display: flex;
      justify-content: center;
      height: 10%;

      button {
        width: 280px;
        height: 51px;
        background: $gold;
        color: $blue;
        font-size: 18px;
        font-weight: 600;
        border-radius: 10px;
        border: 1px dolid $gold;

        @include scr-2k {
          width: 330px;
          height: 62px;
          font-size: 21px;
        }

        @include scr-4k {
          width: 350px;
          height: 66px;
          font-size: 23px;
        }

        &:hover {
          border: 1px solid $gold;
          color: $gold;
          transition: all 100ms ease-in-out;
          background: transparent;
        }
      }
    }
  }

  .exchange {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;

    .exchangeTitle {
      display: flex;
      flex-direction: row;
      color: $gray400;
      font-size: 25px;
      font-weight: 500;
      justify-content: space-between;

      @include scr-2k {
        font-size: 30px;
        line-height: 32px;
      }

      @include scr-4k {
        font-size: 32px;
        line-height: 35px;
      }

    }

    .exchangeButton {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background: $blue;
      padding: 10px 25px;
      border-radius: 10px;
      color: $gold;
      border: 1px solid $gold;
      font-size: 16px;

      @include scr-2k {
        font-size: 17px;
        line-height: 22px;
        width: 280px;
        height: 46px;
      }

      @include scr-4k {
        font-size: 18px;
        line-height: 23px;
        width: 290px;
        height: 49px;
      }
    }

    .boxExchange,
    .bars {
      position: relative;
      display: flex;
      flex-direction: row;
      gap: 23px;

      .icon_btn{
        background: transparent;
        border: 0px;
        width: 50px;
        margin-top:25px;
      }

      .inputs {
        .labels {
          display: flex;
          justify-content: space-between;
          margin: 5px 0px;

          label {
            font-size: 14px;
            font-weight: 500;

            @include scr-2k {
              font-size: 17px;
              line-height: 20px;
            }

            @include scr-4k {
              font-size: 18px;
              line-height: 22px;
            }
          }

          p {
            font-size: 14px;
            font-weight: 500;

            @include scr-2k {
              font-size: 16px;
              line-height: 20px;
            }

            @include scr-4k {
              font-size: 17px;
              line-height: 22px;
            }

            span {
              color: $gold;
            }
          }
        }

        input {
          width: 350px;
          height: 50px;
          background: white;
          color: black;
          border: 0px;
          padding: 20px;
          border-radius: 20px;
          font-size: 16px;
          font-weight: 600;


          @include scr-2k {
            font-size: 17px;
            line-height: 20px;
            width: 500px;
            height: 55px;
          }

          @include scr-4k {
            font-size: 18px;
            line-height: 22px;
            width: 500px;
            height: 55px;
          }

          &:focus-visible {
            outline: white;
          }
        }

        .extra {
          position: absolute;
          top: 42px;
          left: 38%;
          color: $gray;
          opacity: 0.8;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .boxExchange {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 50px;
    }
  }
}

.address {
  label {
    font-weight: 500;
    font-size: 20px;

    @include scr-2k {
      font-size: 23px;
    }
  }

  .input {
    background: #f4f3f3;
    padding: 10px;
    border-radius: 16px;

    input {
      padding: 5px;
      background-color: transparent;
      font-size: 18px;
      width: 100%;
    }
  }
}

.agree {
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    color: #f1f1f1;
    margin: 10px 0px;

    @include scr-2k {
      font-size: 19px !important;
    }

    @include scr-4k {
      font-size: 21px !important;
    }

    a, span {
      cursor: pointer;
      color: #E8CB69;
    }
  }
}

.button {
  display: flex;
  justify-content: center;
  margin-top: 19px;

  .buttonTransact {
    color: $blue;
    font-size: 1rem;
    font-weight: 600;
    background: $gold;
    padding: 10px 60px;
    border-radius: 10px;
    border: 1px solid $gold;

    &:hover {
      background: transparent;
      border: 1px solid $gold;
      color: $gold;
    }
  }
}