@import "../globals.scss";

.container {
  border: 1px solid $gold;
  height: 65vh;
  border-radius: 20px;

  .header_section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      @include Btn;
      width: 180px;
      height: 40px;
      margin: auto 10px;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    gap: 20px;
    margin: 25px 0px;
    overflow-y: scroll;
    height: 50vh;

    @include scr-2k {
      grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
    }

    @include scr-4k {
      grid-template-columns: repeat(auto-fill, minmax(900px, 1fr));
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}