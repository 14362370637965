@import "../globals.scss";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 25px 20px;
  position: relative;

  .verticalDivider {
    height: 150px;
    border-right: 2px solid $gold;
  }

  .marginContainer, .commissionsContainer {
    h1 {
      font-size: large;
      font-size: 30px;
      color: $blue;
    }
    
    span {
      color: $white;
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    
      label {
        color: green;
        font-weight: 600;
        font-size: 16px;
    
        @include scr-2k {
          font-size: 20px;
        }
        @include scr-2k {
          font-size: 22px;
        }
    
        &:first-child {
          margin: 0;
        }
      }
      
      input {
        @include Input;
        width: 250px;
        background-color: $grey500 !important;

        &::placeholder {
          color: $gray;
        }
    
        &:focus {
          outline: none;
        }
    
        .icon,
        .tool {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 20px;
          cursor: pointer;
    
          svg,
          img {
            height: 100%;
          }
        }
    
        input {
          padding: 10px 0;
          flex: 1;
        }
    
        min-height: 47px;
      }
    
      
    
      .formSplit {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        grid-gap: 10px;
    
        .splitInner {
    
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
    
      }
    
      .checkboxSection {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        grid-gap: 10px;
        margin: 0 0 10px;
    
        input[type='checkbox'] {
          margin: 2px 0 0;
        }
      }
    
      .checkboxSectionBank {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        grid-gap: 10px;
        margin: 0 0 10px;
    
        input[type='checkbox'] {
          margin: 2px 0 0;
        }
      }
    
      .formButtonSection {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0 0;
      }
    
    }
  }

  

  .timer {
    color: red;
    position: absolute;
    inset: 3.3em 2em auto auto;
  }

  .action {
    margin: 12px 0px;
    display: flex;
    justify-content: space-between;

    .reset {
      background: $blue;
      box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.12);
      border-radius: 40px;
      font-weight: 500;
      font-size: 18px;
      line-height: 10px;
      color: #ffffff;
      border: 0px;
      padding: 20px;
    }

    .reset:disabled{
      opacity: 0.5;
      color: white;
      cursor: none;
    }
  }
}

.formErrSection {
  min-height: 16px;

  span {
    font-size: 16px;
    color: red;
  }

}