@import "../globals.scss";

.ParentContainer {
    h1 {
        color: $blue;
        font-size: 30px;

        @include scr-2k {
            font-size: 35px;
        }

        @include scr-4k {
            font-size: 38px;
        }
    }

    p {
        font-size: 16px;
        margin: 15px 0px;
        line-height: 22px;

        @include scr-2k {
            font-size: 25px;
        }

        @include scr-4k {
            font-size: 29px;
        }

        .appsSection {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            grid-gap: 20px;

            .appNamesSection {
                display: flex;
                flex-direction: row;
                align-items: center;
                grid-gap: 10px;
                
                a {
                    color: $blue;
                    font-weight: bold;
                    text-decoration: underline;
                    margin: 0px 1px 0;
                }
            }
        }
    }

    .Qrcode {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .forms {
            width: 100%;            

            label {
                color: $blue;
                font-weight: 600;
                text-align-last: left;
                margin-right: auto;
            }

            .grid {
                display: grid;
                grid-template-columns: 2.5fr 1.5fr;
                grid-template-rows: 1fr;
                justify-content: center;
                align-items: center;
                text-align: center;
                grid-gap: 15px;
                margin: 5px 0px;

                .resetSection {
                    text-align: end;

                    .reset {
                        color: $blue;
                        margin-left: auto;
                        background: white;
                        font-weight: 600;
                        font-size: 16px;
                        text-align: end;
                    }
                }
            }

            p {
                margin: 10px 0px;

                .smtext {
                    color: $blue;
                    font-weight: 600;
                    line-height: 20px;
                }
            }

            .btndiv {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                button {
                    @include Btn;
                    margin: 10px 0px;
                    width: 220px;
                    height: 55px;
                    text-align: center;

                    @include scr-2k {
                        width: 230px;
                        height: 57px;
                    }

                    @include scr-4k {
                        width: 240px;
                        height: 58px;
                    }
                }
            }
        }
    }

    .btnDiv {
        display: flex;
        justify-content: space-between;
        margin: 25px 0px;

        button {
            @include Btn;
            width: 190px;
            height: 50px;

            @include scr-2k {
                width: 210px;
                height: 53px;
            }

            @include scr-4k {
                width: 220px;
                height: 56px;
            }
        }
    }
}