@import "../globals.scss";

.Container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;

    h1 {
        text-align: start;
        color: $blue;
    }

    h3 {
        font-size: 21px;
        text-align: center;

        span {
            margin: 0px 3px;
        }
    }

    h5 {
        text-align: center;
    }

    img {
        width: 230px;
        height: 230px;
    }

    button{
        @include Btn;
        width: 200px;
        height: 35px;
    }

    p {
        @include Fonts(16px, 22px, 500);
    }

    .copyBtn {
        cursor: pointer;
    }
}