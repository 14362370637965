@import "../globals.scss";

@mixin Fonts {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
}

.head {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.cryptoContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;

    .cryptoCurrency {
        display: flex;
        flex-direction: column;
        width: 40%;
        // pad?ding: 5px;
        height: 300px;
        // overflow-y: auto;

        .header {
            font-size: 26px;
            font-weight: 500;
            color: white;
            line-height: 34px;
            border-bottom: 1px solid white;
            padding-bottom: 6px;
        }

        &>div {
            font-size: 19.2px;
            color: white;
            margin: 8px 0px;
            display: flex;
            justify-content: space-between;
        }
    }
}

.currencyContainer {
    display: flex;
    justify-content: space-between;
    width: 0%;
    column-gap: 20px;
}

@media (max-width: 600px) {
    .cryptoContainer {
        .cryptoCurrency {
            width: 60%;
        }
    }

    .selected {
        color: #E8CB69;
        font-size: 30px;
        font-weight: 500;
        line-height: 44px;
        border-bottom: 1px solid #E8CB69;
        padding-bottom: 6px;
    }
}