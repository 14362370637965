@import "../globals.scss";

@mixin Fonts {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}

h1 {
  font-weight: 700;
}

.table {
  width: 100%;
  border-collapse: collapse;
  overflow-y: auto;

  thead {
    text-align: center;

    th {
      color: $white;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
    }
  }

  tr {
    border-bottom: 1px solid white;
    margin: 10px 0px;
    font-size: 14px;

    td {
      text-align: center;
      min-height: 120px;
      @include Fonts;
      font-size: 14px;



      &.approved {
        color: $success;
        @include Fonts;

      }

      &.pending {
        color: $gold;
        @include Fonts;

      }

      &.rejected {
        color: $error;
        @include Fonts;

      }

      &:nth-child(2) {
        @include Fonts;

      }

      .viewAndApplyBtn {
        background-color: transparent !important;
        color: white !important;
        border: 1px solid $blue !important;
        box-shadow: none !important;
        padding: 3px !important;
        width: 50px !important;
      }
    }
  }
}


.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .btnSection {
    display: flex;
    align-items: center;
    grid-gap: 25px;
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.button {
  float: right;
  margin-right: 1.3rem;
  padding: 10px;
  width: 130px;
  height: 38px;
}