@import "../globals.scss";

.container {
  background: transparent;
  border-radius: 16px;
  display: flex;
  width: 80%;
  height: 155px;
  align-items: center;
  border: 1px solid $gold;
  gap: 20px;
  margin: 20px auto;
  padding: 25px;

}

.text {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 30px;

  .imgsection{
    img{
      width: 80px;
      height: 80px;
      margin: auto;
    }
  }
  .writtendiv{
    h2 {
      margin-bottom: 0.7rem;
      display: flex;
      flex-direction: row;
      color: $gold;
      @include Fonts(30px,35px,600);
    }
  
    p {
      color: white;
      @include Fonts(18px,20px,500);
    }
  }
}

.action {
  align-self: center;
  width: 197px;
  height: 51px;
  font-size: 15px;
  padding: 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success {
  background-color: white;
  box-shadow: 0 0 14px 2px #00000026;

  h2,
  p {
    color: Color("success");
  }

  .icon {
    font-size: 25px;
  }
}

.secondary {
  background-color: lighten(Color("secondary"), 70);

  h2,
  p {
    color: Color("secondary");
  }
}