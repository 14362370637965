@import "../globals.scss";

.container {
  border: 1px solid $brightGold;
  border-radius: 10px;
  height: 65vh;

  table {
    td.status {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      gap: 10px;
      margin: auto;
      justify-content: center;
      width: 200px;

      &.approved {
        color: #1cb46b;
        text-align: center;
        margin: auto;
      }

      &.pending {
        color: #7364d9;
        text-align: center;
        margin: auto;
      }

      &.notApplied {
        color: #db2626;
        text-align: center;
        margin: auto;
      }

      &.rejected {
        color: #db2626;
        text-align: center;
        margin: auto;
      }
    }

    .logsTableCell {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      grid-gap: 10px;

      button {
        font-size: 14px !important;
        padding: 15px !important;
        width: max-content !important;
        min-width: max-content !important;
      }
    }


  }

  .foot {
    padding: 20px 0;
    width: 100%;
    display: grid;
    place-items: center;

    p {
      color: $gold;
    }
  }
}


// ? FinDetails modal
.finDetailsModal {
  h2 {
    color: $blue;
    margin: 0 0 25px;
  }

  .finDetailsSection {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    
    .finDetailItem {
      width: 50%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0 5px 0 0;
      margin: 0 0 15px;

      .finTitle {
        color: $blue;
        font-weight: bold;
      }
    }
  }
}