@import "../globals.scss";

.container {


    h1 {
        font-size: 30px;
        color: $blue;
        margin: 10px 0px;
    }

    .grid_div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        .Innerdiv {
            display: flex;
            border: 2px solid $gray400;
            border-radius: 10px;
            align-items: center;
            margin: 5px 0px;
            padding: 3px 10px;
            cursor: pointer;

            .Imgsection {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .logo {
                    img {
                        width: 55px;
                        height: 55px;
                    }
                }

                .name {
                    margin-left: 7px;

                    h3 {
                        @include Fonts(20px, 22px, 600);
                    }

                    p {
                        color: $gray;
                        font-weight: 500;
                        line-height: 20px;
                    }
                }
            }

            .textsection {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                h3 {
                    @include Fonts(23px, 25px, 600);
                }

                .rate {
                    color: green;
                    @include Fonts(19px, 22px, 500);
                }

                p {
                    @include Fonts(13px, 20px, 500);
                    color: $gray;
                }
            }
        }
    }
}