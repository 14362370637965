@import "../globals.scss";

.container {
    margin: auto;


    h2 {
        @include Fonts(32px, 62px, bold);
        color: $blue;
        margin: 10px 0px;
        font-weight: bolder;
    }

    .innerContainer {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        justify-content: center;
        align-items: center;
        gap: 10px;

        .options {
            border: 2.5px solid $NewGold;
            display: flex;
            flex-direction: column;
            gap: 10px;
            border-radius: 20px;
            padding: 13px;
            position: relative;
            cursor: pointer;

            img {
                width: 55px;
                height: 55px;
            }

            h4 {
                @include Fonts(18px, 20px, 700);
                color: $blue;
            }

            p {
                @include Fonts(16px, 22px, 500);
                color: $NewGold;
                font-weight: 700;
            }

            span {
                position: absolute;
                bottom: 7px;
                right: 5px;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}