@import "../globals.scss";

.container {
  border: 1px solid $brightGold;
  border-radius: 20px;
  height: 65vh;
  table {
    td.status {
      position: relative;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      gap: 10px;
      width: 200px;

      &.approved {
        color: $statusApproved;
      }
      &.pending {
        color: $statusPending;
      }
      &.rejected {
        color: $statusRejected;
      }
      img {
        cursor: pointer;
      }
    }
  }

  .adminAcceptOrRejectDiv {
    width: 108px;
    height: 60px;
    position: absolute;
    top: 20px;
    left: -35px;
    box-shadow: 0px 2px 4px 0px #00000040;
    background: #fbfdfd;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15%;
    padding: 5% 5%;

    .closeBtn {
      position: absolute;
      top: 2px;
      right: 4px;
      background: transparent;
      border: none;
      color: white;
    }

    .acceptOrRejectBtn {
      background-color: transparent;
      outline: none;
      border: none;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
      transition: all 0.2s ease;

      &:hover {
        transform: scale(1.15);
      }
    }
  }

  .userSearchForm {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    grid-gap: 15px;
    padding: 0 10px;

    h4 {
        color: $white;
        font-size: 22px;
        font-weight: bold;
    }

  .searchBarSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 15px;
    margin: 10px 0px;

    .searchInputSection {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: $grey500;
        border-radius: 10px;

        input {
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 10px;
            background: $grey500;
            border: 1px solid $grey500;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            padding: 0 20px;

            &::placeholder {
                color: $gray;
                opacity: 1;
            }

            .icon,
            .tool {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 20px;
                cursor: pointer;

                svg,
                img {
                    height: 100%;
                }
            }

            input {
                padding: 10px 0;
                flex: 1;
            }

            &:focus {
                outline: none;
            }
        }

        select {
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 10px;
            border: 4px solid $brightGold;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
            border-radius: 0 10px 10px 0;
            padding: 10px 20px;
            background: $brightGold;
            margin: 0;

            &:focus {
                outline: none;
            }
        }
        
        .dropdownBtn {
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 10px;
            border: 4px solid $brightGold;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
            border-radius: 0 10px 10px 0;
            padding: 10px 20px;
            background: $brightGold;
            margin: 0;

            &:focus {
                outline: none;
            }
        }
    }

    button {
        height: 47px !important;
        background-color: $brightGold !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
  }
  }
}
