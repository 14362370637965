@import "../globals.scss";

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Outline_Div {
    display: flex;
    flex-direction: column;
    gap: 70px;
    padding: 20px;

    .Inner_DIV {
        display: flex !important;
        width: 450px !important;
        justify-Content: space-between !important;
    }

}

.inputs {
    .labels {
        display: flex;
        flex-Direction: column !important;
        gap: 30px !important;

        .label_main {
            font-Weight: 700 !important;
            font-size: 24px !important;
            color: #DBB42D !important;

            @include scr-2k {
                font-size: 26px !important;
            }
    
            @include scr-4k {
                font-size: 29px !important;
            }
        }

        .label_parent {
            font-Weight: 700;
            font-Size: 17px;

            @include scr-2k {
                font-size: 19px !important;
            }
    
            @include scr-4k {
                font-size: 21px !important;
            }
        }
    }
}

.btn_divs {
    display: flex !important;
    gap: 70px !important;
    margin-Top: 70px !important;
    padding-Left: 15px !important;
}