@import "../globals.scss";

.container {
  display: flex;
  gap: 20px;
  justify-content: center;

  @include scr-2k {
    gap: 30px;
  }
  
  @include scr-4k {
    gap: 40px;
  }

  .number {
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
    background-color: $brightGold;
    border: $brightGold 3px solid;
    border-radius: 50%;
    cursor: pointer;

    @include scr-2k {
      width: 55px;
      height: 55px;
    }
    
    @include scr-4k {
      width: 70px;
      height: 70px;
    }

    p {
      color: $gold;
      font-weight: bold;

      @include scr-2k {
        font-size: 25px;          
      }
      
      @include scr-4k {
        font-size: 32px;          
      }
    }
    &.active {
      background-color: $brightGold;
      p {
        color: $white;
      }
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
