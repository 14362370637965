@import "../globals.scss";

.transactionList {
    width: 100%;
}

.transactionItem {
    display: grid;
    align-items: center;
    grid-template-columns: auto auto;
    grid-template-rows: 1fr;
    grid-gap: 20px;
    height: 75px;

    @include scr-2k {
        height: 85px;
        gap: 20px;
    }

    @include scr-4k {
        height: 88px;
        gap: 20px;
    }

    .main_img {
        @include scr-2k {
            width: 70px;
            height: 65px;
        }

        @include scr-4k {
            width: 76px;
            height: 68px;
        }
    }

    .transactionDetail {
        margin-right: auto;
        font-weight: 500;
        font-size: 18px;

        @include scr-2k {
            font-size: 22px;
            line-height: 30px;
        }

        @include scr-4k {
            font-size: 24px;
            line-height: 35px;
        }

        .date {
            color: white;
        }

        .transactionMessage {
            color: white;
        }
    }

    .transactionStatus {
        margin: 0px 0px 0px auto;

        .amount {
            font-weight: 500;
            font-size: 20px;
            text-align: end;

            @include scr-2k {
                font-size: 22px;
                line-height: 25px;
            }

            @include scr-4k {
                font-size: 24px;
                line-height: 28px;
            }
        }

        .status {
            font-weight: 600;
            font-size: 14px;
            text-align: end;

            @include scr-2k {
                font-size: 17px;
                line-height: 25px;
            }

            @include scr-4k {
                font-size: 19px;
                line-height: 28px;
            }
        }
    }
}

.no_transaction {
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
        text-align: center;
        color: $gold;
    }
}