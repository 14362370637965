@import "../globals.scss";

.ParentContainer {
    height: 425px;

    @include scr-2k {
        height: 550px;
    }

    @include scr-4k {
        height: 600px;
    }

    h1 {
        color: $blue;
        font-size: 30px;

        @include scr-2k {
            font-size: 35px;
        }

        @include scr-4k {
            font-size: 38px;
        }
    }

    .Qrcode {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .forms {

            label {
                color: $blue;
                font-weight: 600;
                text-align-last: left;
                margin-right: auto;
            }

            .grid {
                display: grid;
                grid-template-columns: 2.5fr 1.5fr;
                grid-template-rows: 1fr;
                justify-content: center;
                align-items: center;
                text-align: center;
                grid-gap: 15px;
                margin: 5px 0px;

                .reset {
                    color: $blue;
                    margin-left: auto;
                    background: white;
                    font-weight: 600;
                    font-size: 16px;
                }
            }

            p {
                margin: 10px 0px;

                .smtext {
                    color: $blue;
                    font-weight: 600;
                    line-height: 20px;
                }
            }

            .btndiv {
                text-align: center;

                .verifybtn {
                    @include Btn;
                    margin: 10px 0px;
                    width: 220px;
                    height: 55px;
                    text-align: center;

                    @include scr-2k {
                        width: 230px;
                        height: 57px;
                    }

                    @include scr-4k {
                        width: 240px;
                        height: 58px;
                    }
                }
            }
        }
    }
}