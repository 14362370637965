@import "../globals.scss";

.backdrop {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.562);
}

.container {
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  max-width: 600px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  overflow-y: auto;

  @include xl-scr {
    max-width: 750px !important;
  }

  @include scr-2k {
    max-width: 850px !important;
  }

  @include scr-4k {
    max-width: 950px !important;
  }

  @include phone {
    max-width: 95%;
  }

  label {
    color: $NewCol;

    @include phone {
      font-size: 21px;
    }
  }

  .innercontainer {
    padding: 20px;
    width: 100%;
    height: 100%;
    position: relative;

    .close {
      background-color: $gold;
      position: absolute;
      top: -1px;
      right: -1px;
      width: 61px;
      height: 61px;
      border-bottom-left-radius: 20px;
      display: grid;
      place-items: center;
      border: none;

      &:hover {
        background: #981616;
      }

      @include phone {
        width: 45px;
        height: 45px;
      }

      svg {
        @include phone {
          width: 18px;
          height: 18px;
        }
      }
    }

    @include phone {
      padding: 10px;
    }

    .component {
      max-width: 564px;
      margin: 20px auto;

      @include xl-scr {
        max-width: 650px;
        margin: 30px auto;
      }

      @include scr-2k {
        max-width: 750px;
        margin: 40px auto;
      }

      @include scr-4k {
        max-width: 860px;
        margin: 65px auto;
      }

      h1 {
        margin: 10px 0px;
        text-align: left;
      }
    }
  }
}