@import "../globals.scss";

.container {
    border: 1px solid $gold;
    border-radius: 20px;
    height: 65vh;
    overflow-y: scroll !important;

    &::-webkit-scrollbar {
        width: 0px;
        border-radius: 20px;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        height: 7vh;

        .sellHeading {
            color: $gold;
            margin: 5px 0px;
        }

        select {
            background: transparent;
            color: white;
            border: 1px solid $gold;
            border-radius: 10px;
            height: 50px;
            font-size: 16px;
            font-weight: bold;
            padding: 5px;
            width: max-content;

            @include scr-2k {
                font-size: 22px;
                height: 60px;
                padding: 0 15px;
            }

            @include scr-4k {
                font-size: 35px;
                height: 80px;
                padding: 0 20px;
            }

            option {
                background: white;
                color: $blue;
                padding: 5px;
            }
        }
        
        .statusDropdown {
            background: transparent;
            color: white;
            border: 1px solid $gold;
            border-radius: 10px;
            height: 50px;
            font-size: 16px;
            font-weight: bold;
            padding: 5px 10px;
            width: max-content;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            grid-gap: 20px;

            @include scr-2k {
                font-size: 22px;
                height: 60px;
                padding: 0 15px;
            }

            @include scr-4k {
                font-size: 35px;
                height: 80px;
                padding: 0 20px;
            }

            option {
                background: white;
                color: $blue;
                padding: 5px;
            }

            img {
                width: 20px;
                height: 20px;
            }
        }

        button {
            @include Btn;
            width: 262px;
            height: 45px;
        }

        .BuyDep {
            @include Btn;
            width: 170px;
            height: 45px;
            margin: 0px 10px;
        }

        img {
            width: 30px;
            height: 30px;

            @include scr-2k {
                width: 35px;
                height: 35px;
            }

            @include scr-4k {
                width: 55px;
                height: 55px;
            }
        }
    }

    .userSearchForm {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        grid-gap: 15px;
        padding: 0 10px;

        h4 {
            color: $white;
            font-size: 22px;
            font-weight: bold;
        }

        .searchBarSection {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            grid-gap: 15px;
            margin: 10px 0px;

            .searchInputSection {
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: $grey500;
                border-radius: 10px;

                input {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    gap: 10px;
                    background: $grey500;
                    border: 1px solid $grey500;
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
                    border-radius: 10px;
                    padding: 0 20px;

                    &::placeholder {
                        color: $gray;
                        opacity: 1;
                    }

                    .icon,
                    .tool {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 20px;
                        cursor: pointer;

                        svg,
                        img {
                            height: 100%;
                        }
                    }

                    input {
                        padding: 10px 0;
                        flex: 1;
                    }

                    &:focus {
                        outline: none;
                    }
                }

                select {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    gap: 10px;
                    border: 4px solid $brightGold;
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
                    border-radius: 0 10px 10px 0;
                    padding: 10px 20px;
                    background: $brightGold;
                    margin: 0;

                    &:focus {
                        outline: none;
                    }
                }
                
                .dropdownBtn {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    gap: 10px;
                    border: 4px solid $brightGold;
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
                    border-radius: 0 10px 10px 0;
                    padding: 10px 20px;
                    background: $brightGold;
                    margin: 0;
                    max-width: 150px;

                    &:focus {
                        outline: none;
                    }

                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            button {
                height: 47px !important;
                background-color: $brightGold !important;
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 150px;
            }
        }


    }

    .transaction_module {
        margin: 7px 0px;
        overflow-y: auto;
        height: 37.5vh;

        table {
            width: 100%;
            margin: 7px 0px;

            thead {
                margin: 10px 0px;
                border-bottom: 2px solid red;

                tr {
                    border-bottom: 2px solid $white;
                    height: 50px;

                    th {
                        color: $white;
                        @include Fonts(20px, 20px, bold);
                        border-bottom: 2px soldi $white;

                        @include scr-2k {
                            font-size: 25px !important;
                            line-height: 25px;
                            padding: 25px 20px;
                        }

                        @include scr-4k {
                            font-size: 40px !important;
                            line-height: 32px;
                            padding: 35px 20px;
                        }
                    }
                }
            }

            tbody {

                tr {
                    text-align: center;
                    border-bottom: 1px solid $white;
                    height: 50px;

                    th {
                        padding: 0 10px;
                    }

                    td {
                        color: white;
                        padding: 0 10px;
                        @include Fonts(15px, 35px, 500);

                        .ancor {
                            text-decoration: underline;
                        }
                        .ProcessBTN{
                            @include Btn;
                            width: 144px;
                            font-size: 15px !important;
                            height: 36px;
                            margin: auto 0px;
                        }

                        @include scr-2k {
                            font-size: 20px !important;
                        }

                        @include scr-4k {
                            font-size: 33px !important;
                        }

                    }

                    .status {
                        color: white;
                        @include Fonts(15px, 35px, 500);
                        text-transform: capitalize;

                    }

                    .view_btn {
                        @include Btn;
                        font-size: 13px;
                        height: 35px;
                        width: 120px;
                    }

                    .btnDiv {
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        height: inherit;

                        .btnapproval {
                            @include Btn;
                            font-size: 13px;
                            height: 35px;
                            width: 175px;
                            margin: 0px 3px;

                            &:disabled {
                                opacity: 0.8;
                                cursor: not-allowed;

                            }
                        }

                        .approvals {
                            background: transparent;
                            border: 0px;
                            margin: 0px 2px;
                        }
                    }
                }

                .title_success {
                    color: white;
                    @include Fonts(15px, 22px, 500);
                    text-transform: capitalize;
                }

                .DetailsBTN {
                    @include Btn;
                    height: 35px;
                    width: 110px;
                    font-size: 14px;
                }
            }
        }
    }

    .transaction_module2 {
        margin: 7px 0px;
        overflow-y: auto;
        height: 46.5vh;

        table {
            width: 100%;
            margin: 7px 0px;

            thead {
                margin: 10px 0px;
                border-bottom: 2px solid red;

                tr {
                    border-bottom: 2px solid $white;
                    height: 50px;

                    th {
                        color: $white;
                        @include Fonts(20px, 20px, bold);
                        border-bottom: 2px soldi $white;

                        @include scr-2k {
                            font-size: 25px !important;
                            line-height: 25px;
                            padding: 25px 20px;
                        }

                        @include scr-4k {
                            font-size: 40px !important;
                            line-height: 32px;
                            padding: 35px 20px;
                        }
                    }
                }
            }

            tbody {

                tr {
                    text-align: center;
                    border-bottom: 1px solid $white;
                    height: 50px;

                    th {
                        padding: 0 10px;
                    }

                    td {
                        color: white;
                        padding: 0 10px;
                        @include Fonts(15px, 35px, 500);

                        .ancor {
                            text-decoration: underline;
                        }

                        @include scr-2k {
                            font-size: 20px !important;
                        }

                        @include scr-4k {
                            font-size: 33px !important;
                        }

                    }

                    .status {
                        color: white;
                        @include Fonts(15px, 35px, 500);
                        text-transform: capitalize;

                    }
                    .view_btn {
                        @include Btn;
                        font-size: 13px;
                        height: 35px;
                        width: 120px;
                    }

                    .btnDiv {
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        height: inherit;

                        .btnapproval {
                            @include Btn;
                            font-size: 13px;
                            height: 35px;
                            width: 135px;
                            margin: 0px 3px;

                            &:disabled {
                                opacity: 0.8;
                                cursor: not-allowed;

                            }
                        }

                        .approvals {
                            background: transparent;
                            border: 0px;
                            margin: 0px 2px;
                        }
                    }
                }

                .title_success {
                    color: white;
                    @include Fonts(15px, 35px, 500);
                    text-transform: capitalize;
                }

                .DetailsBTN {
                    @include Btn;
                    height: 35px;
                    width: 110px;
                    font-size: 14px;
                }
            }
        }
    }
}

.ProcessBTN{
    @include Btn;
    width: 144px;
    font-size: 15px !important;
    height: 36px;
    margin: auto 0px;
}