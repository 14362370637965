@import "../globals.scss";

.submitted{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    font-weight: 500;
    
    .orderStatus{
        font-size: 25px;

        @include scr-2k {
            font-size: 27px !important;
        }

        @include scr-4k {
            font-size: 29px !important;
        }
    }

    .orderNumber{
        font-size: 18px;
        color: #707070;

        @include scr-2k {
            font-size: 21px !important;
        }

        @include scr-4k {
            font-size: 24px !important;
        }
    }


    .trackOrderBtn{
        width: 30%;
        padding: 20 10px;
        min-width: fit-content;
        background-color: #7364D9;
        text-align: center;
        border-radius: 40px;
        color: white;
        box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.12);
    }
}