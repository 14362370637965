@import "../globals.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
  max-width: 550px;
  margin: auto;

  .heading {
    font-size: 32px;
    line-height: 35px;
    font-weight: 700;
    color: $blue !important;
    font-family: $fontfam2;

    @include phone {
      font-size: 25px;
      font-weight: 700;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;

    input {
      font-size: 16px;

      @include phone {
        font-size: 14px;
      }
    }

    .forgot {
      color: #4d4f51;
      font-size: 16px;
      font-weight: 500;
      margin-top: 3px;

      @include phone {
        font-size: 15px;
      }
    }

    .action {
      display: flex;
      align-items: center;
      justify-content: center;

      .remember {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
          font-size: 18px;
        }
      }
    }

    .linkText {
      font-weight: 500;
      font-size: 18px;
      margin-top: 30px;
      text-align: center;

      @include scr-2k {
        font-size: 25px;
      }

      @include scr-4k {
        font-size: 27px;
      }

      span {
        color: $blue;
        cursor: pointer;
        font-weight: 600;
        border-bottom: 1px solid $blue;
      }
    }
  }
}

@media (min-width:2559px) {

  .container {
    max-width: 800px;
    gap: 25px;

    .heading {
      font-size: 32px * 1.3;
    }

    form {
      gap: 35px;

      .forgot {
        font-size: 25px;
      }

      .action {

        .remember {
          gap: 15px;

          input {
            width: 25px;
            height: 25px;
          }

          p {
            font-size: 25px;
          }
        }
      }
    }
  }

}

@media (min-width:3840px) {

  .container {
    max-width: 1200px;
    gap: 45px;

    .heading {
      font-size: 32px * 1.5;
    }

    form {
      gap: 45px;

      .forgot {
        font-size: 27px;
      }

      .action {

        .remember {
          gap: 15px;

          input {
            width: 32px;
            height: 32px;
          }

          p {
            font-size: 27px;
          }
        }
      }
    }
  }

}