@import "../globals.scss";

.container_fourteen {
    background-color: #091A49;
    width: 100vw;
    padding: 20px;
    font-family: $fontfam2;

    align-items: center;
    justify-content: space-between;
    margin-top: auto;

    .mainlink {
        color: $white;
        font-weight: 600;
    }
}
