@import "../globals.scss";

@mixin Inputs {
    background: $grey500;
    border: 1px solid $grey500;
    width: 564px;
    height: 51px;
    font-weight: 600;
    font-size: 15px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    color: $blue;
    padding: 13px;
  
    @include scr-2k {
      width: 100%;
      height: 75px;
      padding: 20px 10px;
      font-size: 25px;
    }
  
    @include scr-4k {
      width: 100%;
      height: 100px;
      padding: 20px 10px;
      font-size: 40px;
    }
  
    &:disabled {
      opacity: 0.5;
      width: 100%;
      color: $gray;
    }
    &::placeholder {
      opacity: 0.7;
      color: $gray200;
    }
  
    &:focus-visible {
      outline: white;
    }
  }

.Container {
    font-family: $fontfam2;
    h1 {
        font-size: 27px;
        color: $blue;
        font-weight: 700;
        font-family: $fontfam2;
    }

    .inputSection {
        form {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            flex-direction: column;
            margin: 25px 0px;

            .addAdminDropdownBtn {
                @include Inputs;
                margin: 10px 0px;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }

            input {
                padding: 10px;
                outline: white;
                background: $grey500;
                width: 100%;
                height: 50px;
                border: 0px;
                border-radius: 10px
            }

            button {
                @include Btn;
                width: 190px;
                height: 50px;
            }
        }

    }
}