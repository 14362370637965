@import "../globals.scss";

.submitted {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    row-gap: 30px;
    font-weight: 500;

    .orderStatus {
        font-size: 25px;
    }

    .orderNumber {
        font-size: 18px;
        color: #707070;
    }

    .trackerModule {
        width: 93%;
        display: flex;
        align-items: center;
        justify-content: center;

        .step {
            background-color: #C4C4C4;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 100px;

            .iconContainer {
                background-color: white;
                border-radius: 50%;
            }
        }

    }

    .btnContainer {
        width: 100%;
        display: flex;
        gap: 20px;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;

        .cancelBtn {
            width: 30%;
            padding: 10px;
            min-width: fit-content;
            background-color: #D2D2D6;
            text-align: center;
            border-radius: 40px;
            box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.12);
        }

        .confirmBtn {
            width: 30%;
            padding: 10px;
            min-width: fit-content;
            background-color: #7364D9;
            text-align: center;
            border-radius: 40px;
            color: white;
            box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.12);
        }
    }
}

.strip {
    width: 26%;
    background-color: white;
    padding: 4px 10px;
}


.detailModule {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;


}

.stepDetail {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 30;

    .title {
        font-size: 20px;
        color: $gold;
        margin: 6px 0px;
        line-height: 30px;

        @include scr-2k {
            font-size: 22px !important;
        }

        @include scr-4k {
            font-size: 25px !important;
        }

    }

    .description {
        text-align: center;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.843);

        @include scr-2k {
            font-size: 27px !important;
        }

        @include scr-4k {
            font-size: 32px !important;
        }
    }
}