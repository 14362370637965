@import "../globals.scss";

.heading {
    font-size: 30px;
    color: $blue;
    margin: 15px 0px;
    font-weight: 700;
}

.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    div {
        h4 {
            margin: 10px 0px;
            color: $blue;
            font-weight: 600;
        }

        .customer_id {
            text-decoration: underline !important;
            color: $gold !important;
        }
    }
}