@import "../globals.scss";

.container {
  height: 65vh;
  border: 1px solid $gold;
  border-radius: 20px;


  .Crytodiv {
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
    margin: 5px 0px;


    .Innerdiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0px;
      border-radius: 10px;
      border: 1px solid $gold;
      padding: 10px 15px;
      width: 250px;

      @include scr-2k {
        width: 300px;
      }

      @include scr-4k {
        width: 450px;
      }

      .Imgsection {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include scr-2k {
          gap: 15px;
        }

        @include scr-4k {
          gap: 25px;
        }

        .logo {
          img {
            width: 40px;
            height: 40px;

            @include scr-2k {
              width: 50px;
              height: 50px;
            }

            @include scr-4k {
              width: 65px;
              height: 65px;
            }
          }
        }

        .name {
          margin-left: 7px;

          h3 {
            // @include Fonts(18px, 22px, 600);
            font-size: 18px;
            color: $white;

            @include scr-2k {
              font-size: 25px;
              line-height: 38px;

            }

            @include scr-4k {
              font-size: 32px;
              line-height: 45px;
            }
          }

          p {
            color: $gray;
            font-weight: 500;
            line-height: 20px;

            @include scr-2k {
              font-size: 23px;
              line-height: 35px;
            }

            @include scr-4k {
              font-size: 28px;
              line-height: 42px;
            }
          }
        }
      }

      .textsection {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;

        h3 {
          font-size: 21px;
          color: $white;

          @include scr-2k {
            font-size: 25px;

          }

          @include scr-4k {
            font-size: 32px;
          }
        }

        .rate {
          color: green;
          font-size: 17px;

          @include scr-2k {
            font-size: 22px;
            line-height: 35px;
          }

          @include scr-4k {
            font-size: 27px;
            line-height: 42px;
          }
        }

        p {
          @include Fonts(12px, 20px, 500);
          color: $gray;
        }
      }
    }
  }

  .coins {
    display: flex;
    overflow-x: auto;
    width: 100%;
    padding: 20px 0;
    gap: 20px;
    cursor: grab;

    .coin {
      min-width: 350px;
    }

    &::-webkit-scrollbar {
      height: 5px;
      transition: 0.2s;
      cursor: grab;
    }

    &::-webkit-scrollbar-thumb {
      background: #cfcfcf;
      border-radius: 99px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f3f3f3;
      border-radius: 10px;
    }
  }

  .numbers {
    display: flex;
    gap: 30px;
    justify-content: center;
    margin: 35px 0;

    .card {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 9px;
      min-width: 250px;
      align-items: center;

      @include scr-2k {
        width: 300px;
      }

      @include scr-4k {
        width: 450px;
      }

      h6 {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 27px;
        color: $white;

        @include scr-2k {
          font-size: 21px;
          line-height: 28px;
        }

        @include scr-4k {
          font-size: 25px;
          line-height: 32px;
        }
      }

      p {
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 38px;
        color: $gold;

        @include scr-2k {
          font-size: 40px;
        }

        @include scr-4k {
          font-size: 48px;
        }
      }
    }
  }

  .graphContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    padding: 20px;
    padding-top: 15px;
    padding-bottom: 55px;

    @include scr-2k {
      gap: 25px;
    }

    @include scr-4k {
      gap: 45px;
    }

    .text {
      display: flex;
      justify-content: space-between;

      .written {
        .writtenTitle {
          font-weight: 600;
          font-size: 15px;
          line-height: 21px;

          @include scr-2k {
            font-size: 21px !important;
            line-height: 21px;
          }

          @include scr-4k {
            font-size: 25px !important;
            line-height: 32px;
          }
        }

        p {
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          color: $white;

          @include scr-2k {
            font-size: 18px !important;
            line-height: 21px;
            margin: 15px 0;
          }

          @include scr-4k {
            font-size: 23px !important;
            line-height: 32px;
            margin: 15px 0;
          }
        }
      }

      .btndiv {
        select {
          background: $gold;
          padding: 10px;
          color: $blue;
          border-radius: 10px;
          font-size: 14px;
          font-weight: 600;
          border: 1px solid $gold;

          @include scr-2k {
            font-size: 18px !important;
          }

          @include scr-4k {
            font-size: 23px !important;
          }

          option {
            font-size: 14px;

            @include scr-2k {
              font-size: 18px !important;
            }

            @include scr-4k {
              font-size: 23px !important;
            }
          }
        }
      }
    }


    .graph {
      min-height: 18vh;
      gap: 15px;
    }
  }

  .detailedButton {
    color: black;
    border: 1px solid #E8CB69;
    background: #E8CB69;
    text-align: center;
    width: 250px;
    padding: 10px 15px;
    border-radius: 10px;
    cursor: pointer;
    margin: auto;
    font-weight: 600;
  }
}