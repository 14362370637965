@import "../globals.scss";

.container {
  background: $blue;
  height: 89vh;
  justify-content: center;

  .header_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: auto;
    padding: 25px;

    .header_text {
      color: white;
      text-transform: capitalize;
      font-size: 40px;
    }

    .header_partnerBtn {
      background-color: $brightGold;
      color: $blue;
      padding: 15px;
      font-weight: 600;
      border-radius: 20px;
      cursor: pointer;

      &:hover {
        color: $brightGold;
        background: transparent;
        border: 1px solid $brightGold;
      }
    }
  }


  .backdrop {
    background: transparent;
    position: fixed;
    left: -7.29%;
    right: 55.7%;
    top: 40.65%;
    bottom: -27.96%;
    opacity: 0.1;
    z-index: 0;
    user-select: none;
    cursor: none;
  }

  h2 {
    color: white;
    padding: 10px;
    width: 90%;
    margin: auto;
    text-transform: capitalize;
    font-size: 40px;

    @include scr-2k {
      font-size: 50px;
    }

    @include scr-4k {
      font-size: 60px;
    }

  }

  .innerContainer {
    display: flex;
    max-width: 90%;
    margin: auto;
    justify-content: center;
    gap: 50px;
    gap: 50px;
    flex: 1;

    &>* {
      z-index: 1;
    }

    .sidepanel {
      flex: 0.2;
    }

    .child {
      flex: 0.8;
      padding: 20px;

      h1 {
        font-weight: bold;
        font-size: 22px;
        line-height: 33px;
        color: $white;

        @include scr-2k {
          font-size: 32px !important;
          line-height: 38px;
        }

        @include scr-4k {
          font-size: 45px !important;
          line-height: 45px;
        }
      }

      h6 {
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        color: $brightGold;

        @include scr-2k {
          font-size: 20px !important;
          line-height: 38px;
        }

        @include scr-4k {
          font-size: 35px !important;
          line-height: 45px;
        }
      }
    }
  }
}