@import "../globals.scss";

.Container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4{
        color: $blue;
        font-size: 22px;
        line-height: 40px;
        margin: 10px 0px;
        text-align: center
    }
    .btnDiv{
        button{
            @include Btn;
            width: 80px;
            height: 40px;
            margin: auto 10px;
        }
    }
}