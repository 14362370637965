@import "../globals.scss";

.selectContainer {
    width: 50%;
    max-width: 150px;
    top: 0px;
    right: 450px;

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 10px;
        font-family: Gilroy-bold;
        font-weight: 500;
        font-size: 20px;
        color: $white;
        
        span {
            width: 25px;
            border-radius: 50%;
            color: #ffffff;
            background-color: #ff4e4e;
            margin-left: 7px;
            text-align: center;
            font-size: 14px;
            padding: 3px;
        }
    }

    .dropdownListContainer {
        position: absolute;
        z-index: 100;
        width: 370px;
        max-width: 370px;
        border-radius: 20px;
        background-color: $blue;
        border: 1px solid $gold;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        top: 70px;
        left: 0;
        right: 300px;
        padding-bottom: 10px;

        .notificationContainer {
            padding: 7px 10px;
            margin: 0;
            color: #000;
            font-size: 16px;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            height: 300px;

            &::-webkit-scrollbar {
                display: none !important;
                width: 0px;
            }

            .notifications {
                margin: 5px 0px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                border-radius: 15px;
                display: flex;
                column-gap: 10px;
                align-items: center;
                padding: 20px 10px;
                border: 1px solid $brightGold;
                cursor: pointer;

                .image {
                    height: 50px;
                    width: 50px;
                    background: lightgray;
                    border-radius: 50%;

                    span {
                        width: 50px !important;
                        height: 50px !important;
                    }
                }
                
                div {
                    color: $white;
                }

                &:hover {
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
                }
            }

            .adminNotifications {
                margin: 5px 0px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                border-radius: 15px;
                display: flex;
                column-gap: 10px;
                align-items: center;
                justify-content: space-between;
                padding: 20px 10px;
                border: 1px solid $brightGold;
                cursor: pointer;

                .image {
                    height: 50px;
                    width: 50px;
                    background: lightgray;
                    border-radius: 50%;

                    span {
                        width: 50px !important;
                        height: 50px !important;
                    }
                }
                
                div {
                    color: $white;
                }

                &:hover {
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
                }
            }
        }

        .btnSection {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 5px 0;

            button {

                border: 1px solid $gold;

            }

        }
    }
}

.dropdownArrow {
    top: 50px;
    position: absolute;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid $gold;
    background-color: $blue;
}

@media only screen and (max-width: 2600px) {
    .selectContainer {
        .dropdownListContainer {
            right: 250px;
        }
    }
}

@media only screen and (max-width: 2100px) {
    .selectContainer {
        .dropdownListContainer {
            right: 300px;
        }
    }
}

@media only screen and (max-width: 1750px) {
    .selectContainer {
        .dropdownListContainer {
            right: 300px;
        }
    }
}

@media only screen and (max-width: 1536px) {
    .selectContainer {
        .dropdownListContainer {
            right: 300px;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .selectContainer {
        .dropdownListContainer {
            right: 280px;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .selectContainer {
        .dropdownListContainer {
            right: 280px;
        }
    }
}