@import "../globals.scss";

.container {
  padding: 15px;
  height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
}

.table {
  width: 100%;
 
  tr {
    border-bottom: 2px solid $white;
    text-align: center;
    th {
      padding: 20px 20px;
      font-size: 19px;
      line-height: 21px;
      color: $white;
      text-align: center;
      
      @include scr-2k {
        font-size: 25px !important;
        line-height: 25px;
        padding: 25px 20px;
      }
      
      @include scr-4k {
        font-size: 40px !important;
        line-height: 32px;
        padding: 35px 20px;
      }
    }
    td {
      padding: 10px 5px;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
      word-break: break-all;
      
      @include scr-2k {
        font-size: 20px !important;
        line-height: 25px;
        padding: 20px 5px;
      }
      
      @include scr-4k {
        font-size: 33px !important;
        line-height: 32px;
        padding: 30px 5px;
      }
    }
  }
}

.loading {
  width: 100%;
  min-height: 100px;
  display: grid;
  place-items: center;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  

  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: $brightGold;
    
    @include scr-2k {
      font-size: 32px !important;
      line-height: 28px;
    }
    
    @include scr-4k {
      font-size: 45px !important;
      line-height: 40px;
    }
  }
}
