@import "../globals.scss";

.title {
  margin-top: 1rem;
  color: white;
  font-weight: 700;
  margin-left: 1rem;
  font-size: 24px;
}
.container {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  padding: 20px 0;
  margin: 10px 0px;
  height: 38vh;
  overflow-y: scroll;

  &:last-child {
    border-bottom: none;

    h3 {
      color: #979797;
      font-size: 24px;
      margin-left: 1rem;
    }

    .section {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      color: white !important;
      align-items: flex-start;

      h6 {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
      }

      p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        word-break: break-all;

        &.status {
          font-size: 14px;
          line-height: 20px;
          border-radius: 30px;
          color: #fff;
          padding: 5px 20px;

          &.true {
            background: $success;
          }
          &.false {
            background: $error;
          }
        }
      }
      .button {
        display: flex;
        justify-content: center;
      }
    }
  }
  .btnGrp {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .approve {
      background-color: $success;
      color: white;
      font-size: 16px;
      padding: 20px 40px;
      border: none;
      font-weight: 800;
      border-radius: 10px;
    }

    .reject {
      @extend .approve;
      background-color: $error;
    }
  }

  .dividerSection {
    height: 10px;
    border-bottom: 1px solid $gold;
  }
}
