@import "../globals.scss";

.container {
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .top,
  .details,
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  .top {
    .details {
      img {
        width: 48px;
        height: 48px;

        @include scr-2k {
          width: 60px;
          height: 60px;
        }
    
        @include scr-4k {
          width: 75px;
          height: 75px;
        }
      }

      .text {
        h2 {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: white;
          padding: 5px 0px;
          margin: 0;

          @include scr-2k {
            font-size: 32px !important;
            line-height: 38px !important;

          }
      
          @include scr-4k {
            font-size: 45px !important;
            line-height: 45px !important;
          }
        }

        h3 {
          color: $gray;
          font-weight: 400;
          font-size: 24px;

          @include scr-2k {
            font-size: 25px !important;
            line-height: 38px !important;

          }
      
          @include scr-4k {
            font-size: 32px !important;
            line-height: 45px !important;
          }
        }
      }
    }

    p {
      background: transparent;
      border-radius: 10px;
      border:1px solid $gold;
      padding: 15px 20px;
      font-weight: 500;
      font-size: 16px;
      line-height: 10px;
      color: $white;
      cursor: pointer;
    }
  }

  .bottom {
    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: white;

      @include scr-2k {
        font-size: 25px;
        line-height: 38px;

      }
  
      @include scr-4k {
        font-size: 38px;
        line-height: 45px;
      }
    }

    .switch {
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
      border-radius: 40px;
      background-color: #a09898;
      cursor: pointer;
      transition: 0.2s;

      .outer {
        margin: 3px;
        width: 40px;

        .dot {
          transition: 0.2s;
          height: 20px;
          width: 20px;
          background-color: white;
          border-radius: 50%;
        }
      }

      &.active {
        background: $gold;
        border: 2px solid $gold;

        .dot {
          transform: translateX(100%);
        }
      }
    }
  }
}