@import "../globals.scss";

.wrapper {
  color: $white;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .grid {
    text-align: center;

    p {
      text-align: center;
    }

    form {
      div {
        div {
          div {
            div {
              div {
                align-items: center;

                label {
                  color: white !important;
                  margin: 10px 0px;
                }

                div {
                  div {
                    width: auto;
                  }
                }
              }
            }
          }
        }
      }
    }

  }

}

.wrapper2 {
  color: $white;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  .grid2 {
    display: flex;
    column-gap: 20px;
  }
}