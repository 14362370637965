@import "../globals.scss";

.container {

  h1 {
    color: $blue;
    font-size: 30px;
    font-weight: 600;
  }

  .bank {
    display: grid;
    grid-template-columns: 1.1fr 0.9fr;
    grid-template-rows: 1fr 1fr 1fr;
    padding: 20px 0;
    gap: 20px;

    &:last-child {
      border-bottom: none;
    }

    h6 {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      color: $blue;
      font-weight: 700;
    }

    p {
      font-style: normal;
      margin: 3px 0px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      word-break: break-all;

      &.status {
        font-size: 14px;
        line-height: 20px;
        border-radius: 30px;
        color: #fff;
        padding: 5px 20px;

        &.pending {
          background: $blue;
        }

        &.approved {
          background: $success;
        }

        &.rejected {
          background: $error;
        }
      }
    }
  }

  .btnGrp {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .approve {
      background-color: $brightGold;
      color: white;
      font-size: 16px;
      padding: 17px 30px;
      font-weight: 700;
      border: 1px solid $gold;
      border-radius: 10px;
    }

    .reject {
      @extend .approve;
      background-color: $brightGold;
    }
  }
}