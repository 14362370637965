@import "../globals.scss";

.container {
  h2 {
    margin-top: 0.5rem;
  }
  h3 {
    color: #222222;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  table {
    margin-left: 1rem;
    td.status {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      gap: 10px;
      width: 200px;

      &.approved {
        color: $success;
      }
      &.pending {
        color: $blue;
      }
      &.rejected {
        color: $error;
      }
    }
  }
}
