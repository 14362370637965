  @import "../globals.scss";

.container {
  background: transparent !important;
  flex: 0.8;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid $gold;
  border-radius: 20px;
  width: 453px;

  .header {
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .selectionDiv{
      select{
        height: 35px;
        width:  max-content;
        font-size: 14px;
        background-color: $blue;
        color: $gold;
        border-radius: 10px;

        &:hover{
          outline: $gold;
        }
      }

      .dropBtn {
        height: 35px;
        width:  160px;
        min-height: 47px;
        font-size: 14px;
        background-color: $blue;
        color: $gold;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 5px 20px;
        border: 1px solid $gold;
      }
    }

    .title {
      font-weight: 500;
      font-size: 17px;
      color: white;

      @include scr-2k {
        font-size: 35px;
        line-height: 55px;

      }

      @include scr-4k {
        font-size: 40px;
        line-height: 65px;
      }
    }
  }

  .body {
    height: 80%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin: 10px 0px;
  }

  .btndiv {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 10%;

    button {
      width: 280px;
      height: 51px;
      border: 1px solid $gold;
      background-color: transparent;
      color: $gold;
      font-size: 18px;
      font-weight: 600;
      border-radius: 20px;

      @include scr-2k {
        width: 330px;
        height: 62px;
        font-size: 21px;
        margin: 12px 0px;
      }

      @include scr-4k {
        width: 350px;
        height: 66px;
        font-size: 23px;
        margin: 12px 0px;
      }

      &:hover {
        background: $gold;
        color: $blue;
        transition: all 100ms ease-in-out;
      }
    }
    .fiatDiv{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      button{
        @include Btn;
        width: 120px;
        height: 43px;
      }
      select{
        margin: 0px 10px;
        @include Btn;
        width: 160px;
        height: 40px;
      }
    }
  }

}