@import "../globals.scss"; 


.warning{
  display:"flex";
  justify-content:"center";
  background:"green"
}


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  
  .heading{
    font-weight: 700;
    font-size: 30px;
    line-height: 50px;
    color: $blue;
    text-align:left;
    margin-right: auto !important;
    margin: 0px 0px;
  }
  .sub_reason{
    text-align: left;
    margin-bottom: 10px;
    width: 100%;
    font-weight: bold;
  }

.division{
  display:flex;
  flex-direction:column;
  width: 100%;
  font-size: 20px;

  .innerdiv{
    display:flex;
    justify-content:flex-start;
    flex-direction: row;

    h3{
      font-size: 1.1em;
    }
    p{
      font-size: 0.6em;
    }
  }
}

  form {
    width: 100%;
  }
  .button{
    display: flex;
    margin: 25px 0px;
    background: #DBB42D;
  }
}

.warning{
  display: flex;
  justify-content: center;
  align-items: center;
}

.CheckboxSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 20px;
  margin: 20px 0;

  input[type='checkbox'] {
    width: 20px;
    height: 20px;
  }
}

.formContainer {
  max-height: 45vh;
  overflow-y: auto;
  width: 100%;
  padding: 0 5px 0 0;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #F1F1F1;
    border-radius: 20px;
    height: 90%;
    margin-top: 7px;
    margin-bottom: 7px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: $gold;
    border-radius: 20px;
  }
}

.addBankAccForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  label {
    color: $NewCol;
    font-weight: 600;
    font-size: 16px;

    @include scr-2k {
      font-size: 20px;
    }
    @include scr-2k {
      font-size: 22px;
    }

    &:first-child {
      margin: 0;
    }
  }

  input[type='text'], input[type='password'] {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    background: $grey500;
    border: 1px solid $grey500;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 5px 20px;
    margin: 3px 0px;
    width: 100%;

    &::placeholder {
      color: $gray;
    }

    &:focus {
      outline: none;
    }

    .icon,
    .tool {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      cursor: pointer;

      svg,
      img {
        height: 100%;
      }
    }

    input {
      padding: 10px 0;
      flex: 1;
    }

    min-height: 47px;
  }

  .formSplit {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;

    .splitInner {

      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

  }

  .checkboxSection {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 10px;
    margin: 35px 0 10px;

    input[type='checkbox'] {
      margin: 2px 0 0;
    }
  }

  .checkboxSectionBank {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 10px;
    margin: 0 0 10px;

    input[type='checkbox'] {
      margin: 2px 0 0;
    }
  }

  .formButtonSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 0;
  }

}

select {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  background: $grey500;
  border: 1px solid $grey500;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 5px 20px;
  margin: 3px 0px;
  width: 100%;
  min-height: 47px;

  &:focus {
    outline: none;
  }

  option {
    background-color: $lightBlue;
    color: $gold;
    height: 100px;
  }
}

.formErrorText {
  color: $error;
}

.formErrortextSection {
  height: 16px;
  margin: 0 0 7px;
}

.dropBtn,
.dropBtn:active {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
  background: #F1F1F1;
  border: 0 !important;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 5%);
  border-radius: 10px;
  padding: 5px 20px;
  margin: 3px 0px;
  width: 100%;
  min-height: 47px;
}

.currencyDropdown {
  width: 315px !important;
  height: 350px !important;
  overflow-y: auto;
}

.editDropBtn,
.editDropBtn:active {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
  background: #F1F1F1;
  border: 0 !important;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 5%);
  border-radius: 10px;
  padding: 5px 20px;
  width: 100%;
  min-height: 47px;
  margin: 0 !important;
}

.currencySearchInput {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    background: $grey500;
    border: 1px solid $grey500;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 5px 20px;
    margin: 3px 0px;
    width: 100%;
    min-height: 47px !important;

    &::placeholder {
      color: $gray;
    }

    &:focus {
      outline: none;
    }
}