@import '../globals.scss';

.currencyIcon {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background-color: $NewGold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    span {
        color: $blue;
        font-size: 11px;
        font-weight: bolder;
    }
}