@import "../globals.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 5px;

  @include scr-2k {
    gap: 15px;
  }

  @include scr-4k {
    gap: 25px;
  }

  button {
    margin-top: 0.5rem;
    align-self: center;
  }
}