@import "../globals.scss";

.mainlabel {
    font-weight: 700;
    font-size: 15px;
    padding-bottom: 8px;
}

.outline_div {
    display: flex;
    border: 1px solid #E8CB69;
    border-radius: 10px;
    padding-right: 5px;
    align-items: center;
    
    span {
        font-size: 12px;
        padding: 0 10px;
        min-height: 50px;
        display: flex;
        align-items: center;
    }

    .loaderSection {
        background: transparent !important;
        border-Radius: 0px !important;
        width: 225px !important;
        border-Right: 1px solid white !important;
        display: flex;
        flex-direction: row;
        align-items: center;        
    }
    .input_section{
        display: flex;
        flex-direction: column;
    }

    .main_input {
        background: transparent !important;
        color: white !important;
        border-Radius: 0px !important;
        width: 225px !important;
        border-Right: 1px solid white !important;
    }

    .main_input2 {
        background: transparent !important;
        color: white !important;
        border-Radius: 0px !important;
        width: 225px !important;
    }

    .select {
        background: transparent !important;
        border: none !important;
        color: white !important;
        outline: none !important;
        padding-Left: 5px !important;
        font-Size: 12px !important;

        option{
            font-size: 14px;
        }

        @include scr-2k {
            font-size: 16px !important;
        }

        @include scr-4k {
            font-size: 19px !important;
        }
    }

    .dropBtn {
        background: transparent !important;
        border: none !important;
        color: white !important;
        outline: none !important;
        padding-Left: 5px !important;
        font-Size: 12px !important;
        display: flex;
        flex-direction: row;
        align-self: center;
        justify-content: space-between;
        background-color: $blue;
    }

    .select2 {
        background: transparent !important;
        border: 1px solid $gold;
        width: 300px;
        color: white !important;
        font-Size: 12px !important;
        height: 30px !important;
    }

}

.btn_div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 11px 0px;

    .refresh_btn {
        @include Btn;
        height: 40px;
        width: 150px;
        margin: 0px 10px;
    }
}

.balanceDiv {
    padding: 10px 0 0 0 !important
}

.select3 {
    background: transparent !important;
    border: 1px solid $gold;
    width: 355px;
    color: white !important;
    font-Size: 14px !important;
    border-radius: 10px;
    height: 45px !important;
    padding: 0px 7px;

    @include scr-2k {
        font-size: 16px !important;
    }

    @include scr-4k {
        font-size: 19px !important;
    }

    option {
        color: $white;
        padding: 10px;
        height: 25px;
        font-size: 14px;
    }
}

.refreshQuoteUpper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 10px 0;
    display: none;

    @include scr-2k {
        display: flex;
    }

    @include scr-4k {
        display: flex;
    }

    button {
        @include Btn;
        height: 40px;
        width: 180px;
    }
}

.refreshQuoteSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 0 0;

    button {
        @include Btn;
        height: 40px;
        width: 150px;
    }

    @include scr-2k {
        display: none;
    }

    @include scr-4k {
        display: none;
    }
}

.dropBtn {
    background: transparent !important;
    border: 1px solid $gold !important;
    border-radius: 10px;
    color: white !important;
    outline: none !important;
    padding: 0 10px;
    font-Size: 12px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $blue;
    min-height: 50px;
    width: 355px;
}

.currencyDropBtn {
    background: transparent !important;
    border: none !important;
    border-radius: 10px;
    color: white !important;
    outline: none !important;
    padding: 0 10px;
    font-Size: 12px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $blue;
    min-height: 50px;
}

