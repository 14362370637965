@import "../globals.scss";

.container {
  padding: 20px;
  border-radius: 20px;
  &.variant1 {
    background: transparent;
    border: 1px solid $gold;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  }
  &.variant2 {
    background: transparent;
    border: 1px solid $gold;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  }
}
