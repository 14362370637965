@import "../globals.scss";

.Container {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: auto;

    h2 {
        @include Fonts(28px, 50px, 600);
        color: $blue;
    }

    .depositForm {
        margin: 15px 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        
        label {
            margin: 15px 0 3px 0 ;
            color: $blue;
            font-weight: 600;
            font-size: 16px;
        
            @include scr-2k {
              font-size: 20px;
            }
            @include scr-2k {
              font-size: 22px;
            }
          }

        input {
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 10px;
            background: $grey500;
            border: 1px solid $grey500;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            padding: 5px 20px;
            margin: 3px 0px;
            width: 100%;
        
            &::placeholder {
              color: $gray;
            }
        
            .icon,
            .tool {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 20px;
              cursor: pointer;
        
              svg,
              img {
                height: 100%;
              }
            }
        
            input {
              padding: 10px 0;
              flex: 1;
            }
        
            min-height: 47px;
        }

        .dropdownBtn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          background: $grey500;
          border: 1px solid $grey500;
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
          border-radius: 10px;
          padding: 5px 20px;
          margin: 3px 0px;
          width: 100%;

          &::placeholder {
            color: $gray;
          }

          .icon,
          .tool {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            cursor: pointer;

            svg,
            img {
              height: 100%;
            }
          }

          input {
            padding: 10px 0;
            flex: 1;
          }

          min-height: 47px;
        }

        .currencyDropdown {
          height: 250px;
          overflow-y: auto;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }

    .btndiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        margin: 25px 0 0;
        width: 100%;

        span {
            text-decoration: underline;
            color: $blue;
            cursor: pointer;
            font-size: 16px;
        }

        button {
            width: 245px;
            height: 55px;
            @include Btn;
            &:hover{
                color: $blue;
                background: #D5B54A;
            }
        }
    }
}